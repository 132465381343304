.TopBar {
  height: 70px;
  background: var(--color-primary);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 0.5em 1em;
}

.checkNotification {
  background: var(--color-text) !important;
  color: var(--color-primary) !important;
  border-radius: 50%;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0em;
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  cursor: pointer;
}

.checkNotification:hover {
  color: white !important;
  background: var(--color-info) !important;
}

.helpAccess {
  position: fixed;
  bottom: 1em;
  right: 1em;
  background: var(--color-text) !important;
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.helpAccessContainer {
  position: fixed;
  right: 1em;
  bottom: 100px;
  width: 250px;
  border-radius: 10px;
  padding: 1em;
  box-sizing: border-box;
  background: var(--color-text) !important;
  transition: all 0.3s;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.helpAccessContainer h4 {
  margin: 0;
  color: var(--color-primary);
}

.helpAccessContainer ul {
  padding-left: 0;
}

.helpAccessContainer ul li {
  list-style: none;
}

.helpAccessContainer ul li a {
  font-size: 0.9em;
  text-decoration: none;
  color: var(--color-primary);
}

.helpAccessContainer ul li a:hover {
  font-weight: 500;
}

.helpAccess span {
  display: none;
}

.helpAccess.close span {
  display: block;
  font-size: 2em;
  color: var(--color-primary);
}

.helpAccess.close svg {
  display: none;
}

.helpAccess svg {
  fill: var(--color-primary) !important;
  width: 1em !important;
  height: 1em !important;
}

.TopBar .titleSection {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.2em;
  color: var(--color-text);
}

.TopBar .optionsTopBar {
  display: flex;
  align-items: center;
}

.searchGeneral {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 1em;
  background: var(--color-primary-variant);
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
  height: 2.3em;
  padding: 0em 0.5em;
  box-sizing: border-box;
}

.searchGeneral input {
  border: 0;
  padding: 0.5em;
  background: transparent;
  width: 70px;
  font-size: 0.9em;
  outline: none;
  pointer-events: none;
  color: var(--color-text);
}

.searchGeneral input::placeholder {
  color: var(--color-text);
}

.searchGeneral .optionsSearchGeneral {
  height: 70%;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.searchGeneral .optionsSearchGeneral button {
  border: 0;
  background: transparent;
  padding: 0.5em;
  pointer-events: none;
}

.searchGeneral .optionsSearchGeneral span {
  font-size: 0.7em;
  height: 100%;
  box-sizing: border-box;
  padding: 0em 0.3em;
  color: var(--color-text);
  /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); */
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-primary);
  border-radius: 5px;
  pointer-events: none;
}

.btnNotifications {
  position: relative;
}

.btnNotifications .numberNotification {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  font-weight: bold;
  background: rgb(197, 18, 18);
  color: white;
  border-radius: 50%;
  pointer-events: none;
}

.TopBar .optionsTopBar .notifications button svg,
.TopBar .optionsTopBar .userMenu button svg,
.TopBar .optionsTopBar .createContentShortcut button svg {
  width: 2.2em;
  height: 2.2em;
  pointer-events: none;
  fill: var(--color-text);
}

.TopBar .optionsTopBar .userMenu button,
.TopBar .optionsTopBar .createContentShortcut button {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
}

.chevronSubmenu {
  width: 1em !important;
  height: 1em !important;
  margin-left: 0.3em;
}

.searchGeneral .optionsSearchGeneral button svg {
  width: 1.4em;
  height: 1.4em;
  fill: var(--color-text);
}

.searchGeneral .optionsSearchGeneral button i {
  font-size: 1.2em;
}

.TopBar button {
  border: 0;
  background: transparent;
  padding: 0.5em;
  outline: none;
  cursor: pointer;
}

.TopBar button i {
  font-size: 2em;
}

.TopBar button.clearSearch i {
  font-size: 1.5em;
}

.TopBar button.clearSearch {
  padding: 0;
}

@keyframes slideLeft {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.keyView {
  position: fixed;
  top: 50%;
  right: 50%;
  width: 500px;
  height: max-content;
  background: var(--color-primary);
  transform: translate(50%, -50%);
  box-shadow: 0 8px 8px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  padding: 1em;
  animation: slideLeft 0.2s;
  z-index: 800;
}

.keyView.toggled {
  animation: slideRight 0.2s linear forwards;
  z-index: -1;
}

.keyview-content {
  width: 90%;
  margin: auto;
}

.keyView textarea {
  height: 100px;
  width: 100%;
  background: #eee;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 1em;
  box-sizing: border-box;
  font-family: inherit;
}

.keyView h3 {
  color: var(--color-text);
  font-size: 1em;
  margin-top: 0.2em;
  margin-bottom: 0.8em;
  display: flex;
}

.keyView .confirmButton {
  background: #222;
  font-size: 1em;
  color: white;
  padding: 0.5em 1em;
  width: 200px !important;
  border-bottom: 6px solid black;
  margin-top: 0em;
}

.keyView .closeButton {
  width: 30px;
  height: 30px;
  background: transparent;
  padding: 0;
  margin: 0;
  margin-left: auto;
  display: grid;
  place-items: center;
}

.keyView .closeButton svg {
  cursor: pointer;
  margin: auto;
  width: 20px;
  height: 20px;
  fill: var(--color-text);
}

.keyView ul {
  padding: 0;
  margin: 0;
  margin-bottom: 1em;
}

.keyView li {
  list-style: none;
  margin-bottom: 0.5em;
  font-size: 0.9em;
  color: #666;
  font-weight: bold;
}

.keyView li span {
  font-weight: normal;
}

.keyView button,
.keyView a {
  cursor: pointer;
  width: 100%;
  border: none;
  font-size: 1em;
  font-weight: 500;
  border-radius: 10px;
  background-color: var(--color-success);
  padding: 1em;
  margin-top: 1em;
  color: var(--color-text);
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms linear;
}

.keyView button:hover {
  background-color: #222;
}

.keyView button:hover > svg {
  color: #fff;
  fill: #fff;
}

.keyView button svg,
.keyView a svg {
  width: 1.5em;
  height: 1.5em;
  fill: white;
  margin-right: 1em;
}

.overloadUser,
.overloadShortcut,
.overloadNofications {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.05);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.modalNofications {
  display: block;
  position: fixed;
  top: 70px;
  right: 0em;
  height: calc(100vh - 70px);
  width: 400px;
  background: var(--color-primary);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  overflow-y: scroll;
  z-index: 10000;
  box-sizing: border-box;
}

.modalNofications h4,
.modalFilters h4 {
  color: #888;
}

.modalNofications.toggled,
.modalFilters.toggled,
.modalShortcut.toggled,
.userModal.toggled,
.Notification.toggled,
.alertContainer.toggled,
.helpAccessContainer.toggled {
  right: -100%;
}

.overloadUser.hidden,
.overloadShortcut.hidden,
.overloadNofications.hidden {
  display: none;
}

.modalFilters {
  position: fixed;
  top: 100px;
  right: 1em;
  width: calc(80% - 2em);
  height: 200px;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  overflow-y: scroll;
}

.userModal {
  position: fixed;
  top: 70px;
  right: 0em;
  width: 300px;
  height: calc(100vh - 70px);
  background: var(--color-primary);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 10000;
}

.userModal .present {
  display: flex;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid #aaa;
}

.userModal .present .avatar i {
  font-size: 2em;
}

.userModal .present .infoUser {
  width: 100%;
  margin-left: 1em;
}

.userModal .present .infoUser h4 {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0em;
  width: 100%;
  color: var(--color-text);
}

.userModal .present .infoUser p {
  font-size: 0.8em;
  color: var(--color-text);
  margin: 0;
}

.userModal ul {
  padding: 0em 1em;
  box-sizing: border-box;
}

.userModal ul li {
  list-style: none;
  margin: 0;
}

.userModal ul li a {
  text-decoration: none;
  color: var(--color-text);
  display: inline-block;
  width: 100%;
  padding: 0.3em 1em;
}

.userModal ul li button {
  background: transparent;
  display: flex;
  justify-content: space-between;
  font-size: 1em !important;
  font-family: inherit;
  width: 100%;
  padding: 0.3em 1em;
  color: white;
  background: #222;
  border-radius: 10px;
  text-align: left;
}

.userModal ul li button svg {
  width: 1.5em;
  height: 1.5em;
  fill: white;
}

.userModal ul li button i {
  font-size: 1em;
  color: var(--color-text);
}

.modalShortcut {
  position: fixed;
  top: 70px;
  right: 0em;
  height: calc(100vh - 70px);
  width: 350px;
  background: var(--color-primary);
  display: flex;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  overflow-y: scroll;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 10000;
}

.Notification {
  position: fixed;
  top: 100px;
  right: 1em;
  min-height: 100px;
  width: 400px;
  background: white;
  border-radius: 10px;
  display: flex;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 1em;
  cursor: pointer;
  z-index: 100000;
}

.Notification .contentNotification {
  width: 90%;
  pointer-events: none;
}

.Notification .contentNotification h4 {
  font-size: 0.9em;
  margin: auto;
  pointer-events: none;
}

.Notification .contentNotification p {
  font-size: 0.7em;
  pointer-events: none;
}

.Notification button {
  width: 10%;
}

.Notification button svg {
  width: 1em;
  height: 1em;
  fill: #222;
  pointer-events: none;
}

.modalShortcut h4 {
  padding: 0.5em 1em;
  margin: 0;
  margin-bottom: 0.5em;
  color: var(--color-text);
}

.modalShortcut ul {
  width: 100%;
  padding: 0;
  margin: 0;
  /* border-top: 1px solid #777; */
}

.modalShortcut ul li {
  list-style: none;
  padding: 0em;
  margin: 0;
  width: 100%;
  /* border-bottom: 1px solid #777; */
  transition: all 0.3s;
}

.modalShortcut ul li a span {
  margin-left: 0.5em;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0.5em;
  font-size: 0.85em;
  background: var(--color-primary-variant);
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.05);
}

.modalShortcut ul li a {
  width: 100%;
  display: inline-block;
  color: var(--color-text);
  text-decoration: none;
  height: 100%;
  transition: all 0.3s;
  font-family: inherit;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7em;
  padding: 0.8em 1em;
}

.modalShortcut ul li:hover {
  background: var(--color-primary-variant) !important;
}

.modalShortcut ul li:hover a {
  font-weight: bold;
  color: white;
}

.modalShortcut ul li:hover span {
  color: #222;
  background: white !important;
}

.createContentShortcut .chevronSubmenu,
.userMenu .chevronSubmenu {
  transform: rotate(360deg);
}

.createContentShortcut.toggled .chevronSubmenu,
.userMenu.toggled .chevronSubmenu {
  transform: rotate(180deg) !important;
}

.alertContainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
}

.alertContainer .Alert {
  background: var(--color-primary);
  width: 700px;
  height: 300px;
  box-sizing: border-box;
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}

.alertContainer .Alert h3 {
  font-size: 1.8em;
  margin: 0;
  color: var(--color-text);
}

.alertContainer .Alert .buttonsAlert {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}

.alertContainer .Alert .buttonsAlert button {
  padding: 1em 3em;
  font-size: 1em;
  border-radius: 10px;
}

.alertContainer .Alert .buttonsAlert button:first-child {
  background: #eee;
  color: #222;
}

.alertContainer .Alert .buttonsAlert button:last-child {
  background: #222;
  color: white;
  font-weight: bold;
}

.Search {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
}

.Search .modalSearch {
  background: var(--color-primary);
  box-sizing: border-box;
  width: 56%;
  height: calc(100vh - 80px);
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.Search .modalSearch .searchInput {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 0.5em 1em;
  box-sizing: border-box;
  border: 1px solid #222;
}

.Search .modalSearch .searchInput svg {
  height: 2em;
}

.Search .modalSearch .searchInput input {
  height: 100%;
  border: none;
  font-size: 1.3em;
  width: 100%;
  padding-left: 0.5em;
  outline: none;
  color: #000;
}

.Search .modalSearch .searchInput input::placeholder {
  color: #000;
}

.Search .modalSearch .searchInput i::before {
  color: #000;
}

.Search .modalSearch .searchInput .clearSearch svg {
  height: 1.2em;
  margin-right: 1em;
}

.Search .modalSearch .searchInput .filterSearch svg {
  height: 1.6em;
}

.Search .modalSearch .commands {
  width: 100%;
  background: white;
  display: flex;
  /* margin-top: auto; */
  border-radius: 10px 10px 0px 0px;
  box-shadow: -4px -3px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5em 1em;
  box-sizing: border-box;
}

.Search .modalSearch .commands .keyContainer {
  font-size: 0.8em;
  margin-right: 1.5em;
}

.Search .modalSearch .commands .keyContainer .key {
  margin-right: 0.5em;
  background: #eee;
  border-bottom: 3px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0.3em 0.6em;
  color: #333;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
}

.Search .modalSearch .commands .keyContainer {
  color: #777;
}

.resultSearch {
  margin: auto;
  height: 100% !important;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: -4px -3px 8px rgb(0 0 0 / 10%);
  align-items: center;
  font-size: 0.9em;
  color: #777;
  height: 300px;
}

.Search.toggled {
  right: -100%;
}

.emptyNotifications {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  box-sizing: border-box;
  padding: 2em;
}

.emptyNotifications i {
  color: var(--color-text);
  font-size: 5em;
}

.emptyNotifications img {
  width: 70%;
}

.emptyNotifications h4 {
  margin: 0em;
  font-size: 1.1em;
  color: var(--color-text);
}

.emptyNotifications p {
  font-size: 0.9em;
  text-align: center;
  color: var(--color-text);
}

.sectionNotification {
  box-sizing: border-box;
  padding: 1em;
  width: 100%;
}

.sectionNotification h4 {
  margin: 0;
  margin-bottom: 0.8em;
  font-weight: 500;
  color: var(--color-text);
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
}

.sectionNotification h4 .allNotifications {
  color: var(--color-text);
  font-size: 0.8em;
  display: inline-block;
  margin-left: auto;
}

.sectionNotification .itemsSectionNotification {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1em;
}

.sectionNotification .itemsSectionNotification .item {
  transition: all 0.3s;
  box-sizing: border-box;
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 10px;
  width: 95%;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  background: var(--color-primary-variant);
  position: relative;
  text-decoration: none !important;
}

.sectionNotification .itemsSectionNotification .item:hover {
  box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.3) !important;
}

.sectionNotification .itemsSectionNotification .item .info {
  font-size: 0.75em;
  width: 70%;
  color: var(--color-text);
}

.sectionNotification .itemsSectionNotification .item b {
  color: var(--color-text);
  width: 80%;
}

.sectionNotification .itemsSectionNotification .item .date {
  font-size: 0.7em;
  margin-left: auto;
  color: var(--color-text);
}

.sectionNotification .itemsSectionNotification .item .status {
  font-size: 0.75em;
  margin: 0;
  margin-left: auto;
  color: #f59323;
}

.readerPDF {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  background: #eee;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 500;
  transition: all 0.5s !important;
}

.react-pdf__Page__canvas {
  border-radius: 10px;
}

.navReaderPDF {
  width: 100%;
  background: white;
  height: 70px;
  margin-bottom: auto;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1em;
}

.readerPDF {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  background: var(--color-primary-variant);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100000;
  transition: all 0.5s !important;
}

.react-pdf__Page__canvas {
  border-radius: 10px;
}

.navReaderPDF {
  width: 100%;
  background: var(--color-primary);
  height: 70px;
  margin-bottom: auto;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1em;
}

.viewContainer {
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  position: relative !important;
}

.viewContainer .pagination {
  position: absolute;
  background: var(--color-text);
  color: var(--color-primary);
  bottom: 0;
  left: 55%;
  box-sizing: border-box;
  padding: 0.5em 1em;
  border-radius: 5px 5px 0px 0px;
}

.viewContainer .pagesSidebar {
  width: 15%;
  height: 100%;
  background: white;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
}

.viewContainer .pagesSidebar p {
  color: black;
  margin: 0;
}

.pagesSidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.pagesSidebar .react-pdf__Document {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.pagesSidebar .react-pdf__Document .react-pdf__Page__canvas {
  width: 100%;
}

.pagesSidebar .react-pdf__Document .react-pdf__Page {
  padding-top: 1em;
  padding-bottom: 1em;
}

.pagesSidebar .react-pdf__Document .react-pdf__Page__canvas {
  width: 60% !important;
  height: auto !important;
  border: 2px solid black;
  overflow: hidden;
}

.pagesSidebar .react-pdf__Document {
  overflow: hidden;
}

.react-pdf__Document * {
  color: transparent !important;
}

.react-pdf__Document {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
}

.react-pdf__Page {
  display: flex;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 4em;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
}

.readerPDF i {
  font-size: 1.5em !important;
  color: #050505 !important;
}

.closeReader {
  margin-right: 1em;
  background: transparent;
}

.downloadReader {
  display: flex;
  align-items: center;
  color: white;
  margin-left: 1em;
}

.readerPDF.toggled {
  right: -100%;
}

.titleReader {
  font-size: 0.8em;
  font-weight: 500;
  margin-right: auto;
  color: var(--color-text);
}

.readerPDF.toggled .pagination {
  display: none;
}

.react-pdf__Page__canvas {
  height: auto !important;
}

.react-pdf__Page__canvas {
  margin: auto;
}

.z-ajust .react-pdf__Page__canvas {
  width: auto !important;
  height: 85vh !important;
}

.z-50 .react-pdf__Page__canvas {
  width: 50% !important;
}

.z-75 .react-pdf__Page__canvas {
  width: 75% !important;
}

.z-100 .react-pdf__Page__canvas {
  width: 100% !important;
}

.z-125 .react-pdf__Page__canvas {
  width: 125% !important;
}

.z-150 .react-pdf__Page__canvas {
  width: 150% !important;
}

.z-175 .react-pdf__Page__canvas {
  width: 175% !important;
}

.z-200 .react-pdf__Page__canvas {
  width: 200% !important;
}

.setActions {
  align-items: center;
  display: flex;
  margin-right: 1em;
  right: 0;
}

.setActions button,
.setActions a {
  background: white !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin: 0em 0.5em;
  border-radius: 50%;
  cursor: pointer !important;
  border: none;
  width: 40px;
  height: 40px;
}

.setActions button i,
.setActions a i {
  margin-right: 0;
  font-size: 1.8em !important;
}

.viewContainer .pagination.before {
  left: 17%;
  top: 55%;
  width: 24px;
  height: 24px;
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  cursor: pointer;
  color: #050505 !important;
  background: transparent;
}

.viewContainer .pagination.next {
  right: 2%;
  left: auto;
  top: 55%;
  width: 24px;
  height: 24px;
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  cursor: pointer;
  color: #050505 !important;
  background: transparent;
}

.setActions .cancelDoc i::before {
  color: #e23b3b !important;
}

.setActions .checkedReader i::before {
  color: #0dac22 !important;
}

.setActions .resendDoc i {
  color: #4661f6 !important;
}

.setActions .downloadReader i::before {
  color: #050505 !important;
}

.setActions .keyReader i::before {
  color: #050505 !important;
}

.resultadosPorSecciones {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 1em 2em;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.resultadosPorSecciones .seccionBusquedad {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.resultadosPorSecciones .seccionBusquedad h4 {
  font-weight: 500;
  width: 100%;
}

.seccionBusquedad .itemDeSeccion {
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  margin-bottom: 0.5em;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: all 0.3s;
}

.seccionBusquedad .itemDeSeccion:hover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.seccionBusquedad .itemDeSeccion h5 {
  margin: 0;
  color: #000;
}

.seccionBusquedad .itemDeSeccion span {
  font-size: 0.8em;
  color: #888;
}

.seccionBusquedad .itemDeSeccion svg {
  height: 2.5em;
  width: auto;
  margin: 0;
  fill: #555;
}

.seccionBusquedad .itemDeSeccion .iconItem {
  margin: 0;
  margin-right: 1em;
}

.seccionBusquedad .itemDeSeccion .iconItem i::before {
  color: #000 !important;
}

.seccionBusquedad .itemDeSeccion .info {
  margin-right: 1em;
}

.seccionBusquedad .itemDeSeccion .enterButton {
  margin-left: auto;
  display: none;
}

.seccionBusquedad .itemDeSeccion:hover .enterButton {
  display: block;
}

.seccionBusquedad .itemDeSeccion:hover .enterButton svg {
  height: 2.5em;
  width: auto;
}

.aceptadoStatus {
  margin-left: 1em;
  display: flex;
  background: #7ec06a;
  color: white !important;
  padding: 0.5em;
  border-radius: 5px;
}

.pendienteStatus {
  margin-left: 1em;
  display: flex;
  background: #e74e4e;
  color: white !important;
  padding: 0.5em;
  border-radius: 5px;
}

.seccionBusquedad .metadata {
  display: flex;
}

.seccionBusquedad .metadata + .enterButton {
  margin-left: 1em;
}

.seccionBusquedad .metadata {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.priceFacturaSearch {
  margin-right: 1em;
  display: inline-block;
  font-weight: bold;
  color: black !important;
}

.labelSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.labelSection hr {
  height: 1px;
  border: none;
  background: black;
  flex-grow: 3;
  pointer-events: none;
}

.labelSection button {
  background: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-left: 1em;
  pointer-events: none;
}

.labelSection.toggled button {
  transform: rotate(180deg);
}

.labelSection button svg {
  width: 20px;
  height: 20px;
  fill: white;
}

.labelSection h4 {
  width: auto !important;
  margin-right: 1em;
  display: inline-flex;
  pointer-events: none;
}

.resultsSeccionBusquedad {
  width: 100%;
}

.capsulas {
  margin-bottom: 1em;
  margin-top: 1em;
  width: 90%;
}

.capsulas::-webkit-scrollbar {
  height: 0px;
}

.capsulas .carrusel {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.capsulas .carrusel button {
  margin-right: 1em;
  margin-bottom: 1em;
  font-family: inherit;
  background: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2em;
  padding: 0.5em 1em;
}

.capsulas .carrusel button.active {
  background: black;
  color: white;
}

.emptyResult {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  background: white;
  height: 100%;
  flex-direction: column;
}

.labelSection button i {
  font-size: 20px;
  color: white;
}

.emptyResult h4 {
  color: black;
  font-size: 2em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.emptyResult button {
  font-size: 1em;
  font-family: inherit;
  background: black;
  color: white;
  padding: 0.5em 1em;
  border-radius: 5px;
}

.emptyResult img {
  width: 50%;
  height: auto;
}

.keyView .metadata li {
  font-size: 0.7em;
  color: black;
}

.keyView label {
  width: 100% !important;
  font-size: 0.9em;
  margin-bottom: 1em;
}

.keyView label select {
  width: 100%;
  margin-top: 0.5em;
  background: var(--color-primary-variant) !important;
  color: var(--color-text);
  padding: 0.5em;
  border-radius: 5px;
  border: none;
  font-family: inherit;
  font-size: 0.9em;
  outline: none;
}

.userMenu .transfer {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  background: #b52b21;
  border-radius: 50%;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.userMenu {
  position: relative;
}

.userMenu .transfer svg {
  fill: white;
  width: 0.8em;
  height: 0.8em;
}

.transferChange {
  background: #b52b21 !important;
}

.keyView label {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.9em;
}

.keyView label input {
  margin-top: 0.5em;
  padding: 0.5em 1em !important;
  font-family: inherit;
  border: none;
  border-radius: 5px;
  color: var(--color-text);
  font-size: 1em;
  background: var(--color-primary-variant) !important;
  outline: none !important;
}

.keyView .searchGeneral {
  width: 100% !important;
  margin-bottom: 1em !important;
  margin-left: 0;
}

.keyView .searchGeneral .optionsSearchGeneral button {
  margin-top: 0em;
}

.keyView .searchGeneral .optionsSearchGeneral button svg {
  fill: black;
}

.zoomContainer {
  margin: auto;
  display: flex;
  align-items: center;
}

.zoomContainer .numberPages {
  margin-right: 1em;
  color: #050505;
}

.zoomContainer .ajustDocument {
  margin-left: 1em;
  background: transparent;
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: 1em;
  font-size: 0.8em;
  border: none;
}

.zoomContainer .zoomController {
  border-right: 1px solid #aaaaaa;
  border-left: 1px solid #aaaaaa;
  cursor: pointer;
  color: #050505;
}

.zoomContainer .zoomController button {
  background: transparent;
  margin: 0em 0.5em;
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 0.8em;
  border: none;
}

.zoomContainer .zoomController button:hover,
.zoomContainer .ajustDocument:hover {
  background: var(--color-primary-variant);
}

@media screen and (max-width: 1024px) {
  .TopBar {
    padding: 0 0.4em;
  }
  .titleReader {
    font-size: 1em;
  }
  .searchGeneral {
    margin-left: 0.2em;
  }
  .Search .modalSearch {
    width: 90%;
  }
}
@media screen and (max-width: 874px) {
  .titleReader {
    font-size: 10px !important;
  }
  .navReaderPDF {
    height: max-content;
  }
  .zoomContainer .zoomController {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .zoomContainer {
    font-size: 12px !important;
    margin: 0;
  }
  .setActions {
    width: 40%;
    margin: 0;
  }
  .setActions a {
    width: 35px;
    margin: 4px;
    height: 40px;
  }
  .setActions button {
    width: 35px;
    margin: 4px;
    height: 40px;
  }
}
