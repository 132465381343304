.AlertPermissionNotification{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EF9D38;
    color: white;
    height: 40px;
    box-sizing: border-box;
    padding: 0em 1em;
}

.AlertPermissionNotification.hiden{
    display: none !important;
}

.AlertPermissionNotification p{
    font-weight: bold;
}

.AlertPermissionNotification .warningSymbol{
    margin-right: auto;
}

.AlertPermissionNotification button{
    margin-left: 1em;
    background: transparent;
    padding: 0em;
    text-decoration: underline;
    font-size: 1em;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
}

.AlertPermissionNotification .closeAlertPermissionNotification{
    margin-left: auto;
}

.AlertPermissionNotification .closeAlertPermissionNotification i{
    font-size: 2em;
}

.AlertPermissionNotification.show ~ .mainViewer, .AlertPermissionNotification.show + .Sidebar{
    height: calc(100% - 40px) !important;
}