@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

button {
  border: none;
  outline: none;
}

.router {
  width: 100%;
}

select::placeholder,
input::placeholder,
input,
select {
  color: var(--color-text);
}

select:disabled,
input:disabled {
  background: var(--color--primary);
  color: var(--color-text);
  opacity: 0.8;
}

.schema_predeterminado {
  --color-primary: rgb(255, 255, 255);
  --color-primary-variant: rgb(247, 247, 249);
  --color-text: rgba(50, 50, 54, 0.87);
  --color-border: #aaaaaa;
  --color-info: #4661f6;
  --color-success: #0dac22;
  --color-warning: #f59323;
  --color-error: #e23b3b;
}

.schema_negro {
  --color-primary: #343a40;
  --color-primary-variant: #212529;
  --color-text: #f8f9fa;
  --color-border: #e9ecef;
  --color-info: #4661f6;
  --color-success: #0dac22;
  --color-error: #e23b3b;
}

.schema_azul_noche {
  --color-primary: #495057;
  --color-primary-variant: #343a40;
  --color-text: #f8f9fa;
  --color-border: #e9ecef;
  --color-info: #4661f6;
  --color-success: #0dac22;
  --color-error: #e23b3b;
}

.schema_azul_oscuro {
  --color-primary: rgb(48, 51, 78);
  --color-primary-variant: rgb(40, 42, 66);
  --color-text: rgba(234, 234, 255, 0.87);
  --color-border: #aaa;
  --color-info: rgb(102, 108, 255);
  --color-success: #0dac22;
  --color-error: #e23b3b;
}

.schema_purpura_oscura {
  --color-primary: #42285a;
  --color-primary-variant: #291135;
  --color-text: #ffffff;
  --color-border: #aaa;
  --color-info: #4661f6;
  --color-success: #0dac22;
  --color-error: #e23b3b;
}

.schema_rojo_oscura {
  --color-primary: #85182a;
  --color-primary-variant: #641220;
  --color-text: #ffffff;
  --color-border: #aaa;
  --color-info: #4661f6;
  --color-success: #0dac22;
  --color-error: #e23b3b;
}

.schema_verde_oscura {
  --color-primary: #3a5a40;
  --color-primary-variant: #344e41;
  --color-text: #ffffff;
  --color-border: #aaa;
  --color-info: #4661f6;
  --color-success: #0dac22;
  --color-error: #e23b3b;
}

.listSection {
  width: 100%;
}

.addContent {
  display: flex;
  width: 180px;
  padding: 0.8em 1.5em;
  background: var(--color-text);
  color: var(--color-primary);
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 0.9em;
  border-radius: 10px;
  margin-left: auto;
  font-weight: 500;
  cursor: pointer;
  transition: 0.1s;
}

.addContent i::before {
  color: var(--color-primary);
  margin-left: 0.5em;
  font-size: 1.2em;
}

.addContent:hover {
  background: var(--color-primary-variant);
  color: var(--color-text);
}

.addContent:hover i {
  color: var(--color-text);
}

.navSection {
  margin-bottom: 1.5em;
  background: var(--color-primary);
  position: relative;
  padding: 0.8em 2em;
  display: flex;
  gap: 1rem;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  border-radius: 0px 0px 10px 0px;
  transition: all 0.3s;
  border-top: 1px solid var(--color-border);
}

.navSection.float {
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.2);
  background: var(--color-primary);
}

.navSection label {
  padding: 0em 1em;
  display: flex;
  align-items: flex-start;
  font-size: 0.75em;
  flex-direction: column;
}

.navSection .sortBy {
  display: flex;
  align-items: center;
}

.navSection .sortBy button {
  margin-top: 0.5em;
}

.navSection .sortBy button {
  font-family: inherit;
  font-size: 1em;
  border-radius: 5px;
  padding: 0.4em 1em;
  border: none;
  color: var(--color-primary);
  background: var(--color-text);
  transition: all 0.3s;
  cursor: pointer;
}

.navSection .sortBy button:hover {
  border-bottom: 4px solid #111;
}

.navSection label .search input {
  font-size: 1em;
}

.navSection label .search {
  display: flex;
  width: 100%;
}

.navSection label .search button svg {
  height: 1em;
  width: 1em;
  fill: var(--color-text);
}

.navSection label .search input {
  border-radius: 5px 0px 0px 5px;
  margin-top: 0.3em;
  width: 100%;
  background: var(--color-primary-variant);
  color: var(--color-text);
}

.navSection label .search input::placeholder {
  color: var(--color-text);
}

.navSection label .search button {
  border: none;
  border-radius: 0px 5px 5px 0px;
  margin-top: 0.3em;
  background: var(--color-primary-variant);
  color: var(--color-text);
  display: flex;
  justify-content: center;
  align-items: center;
}

.navSection svg {
  height: 1.5em;
  margin-right: 1em;
}

.navSection label input,
.navSection label select {
  padding: 0.5em 0.8em;
  border-radius: 5px;
  background: var(--color-primary-variant);
  color: var(--color-text);
  border: none;
  margin-top: 0.3em;
  font-family: inherit;
  outline: none;
  width: 100%;
  font-size: 1em !important;
}

.navSection .title {
  font-weight: 400;
  font-size: 0.85em;
  margin-right: 1em;
}

.addContent svg {
  width: 1.5em;
  height: 1.5em;
  fill: var(--color-primary);
  margin-right: 0em;
  margin-left: 1em;
}

.navSection.float .addContent {
  display: flex;
  padding: 0.8em 1.5em;
  align-items: center;
  border: none;
  font-size: 0.9em;
  border-radius: 10px;
  margin-left: auto;
  font-weight: 500;
  cursor: pointer;
  color: var(--color-primary);
  background: var(--color-text);
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.navSection.float .addContent svg {
  width: 1.5em;
  height: 1.5em;
  fill: var(--color-primary);
  margin-right: 0em;
  margin-left: 1em;
}

.navSection.float label {
  color: var(--color-text);
}

.navSection.float .title {
  color: var(--color-text);
}

.listSection {
  padding: 0em 2em 0em 2em;
}

.listSection .headList {
  width: 100%;
}

.listSection .headList ul {
  width: 100%;
  display: flex;
  /* justify-content: space-around; */
  padding: 0em;
  margin: 0em;
  padding-bottom: 0.8em;
  border-bottom: 1px solid #aaa;
}

.listSection ul li {
  list-style: none;
  text-align: left;
  font-size: 0.85em;
  margin: 0em 0.5em;
}

.listSection ul li input {
  background: var(--color-primary-variant);
  border-radius: 5px;
  border: none;
  border-radius: 5px;
  padding: 0.5em;
}

.sidebarForm .actionForm button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
}

.sidebarForm .actionForm button svg {
  fill: white;
  height: 1.5em;
  width: auto;
  margin-right: 1em;
}

.listSection ul li b {
  font-weight: 500;
}

.listSection .bodyList ul li b {
  font-weight: 700;
  color: var(--color-text);
}

.bodyList {
  width: 100%;
}

.bodyList ul {
  width: 100%;
  padding: 0;
}

.bodyList ul .itemBodyList {
  width: 100%;
  transition: all 0.3s;
}

.bodyList ul .itemBodyList .contentItemBodyList {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
  background: var(--color-primary);
  padding: 1em 0.5em;
  margin-bottom: 0.5em;
  box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  /* cursor: pointer; */
  justify-content: space-between;
}

.bodyList ul .itemBodyList .contentItemBodyList li {
  margin: 0;
  font-size: 0.85em;
  margin: 0em 0.5em;
}

.bodyList ul .itemBodyList .contentItemBodyList li .activo::before {
  width: 0.5em !important;
  height: 0.5em !important;
  overflow: hidden;
  content: "";
  border-radius: 50%;
  margin-right: 0.5em;
  background: #31a863;
}

.bodyList ul .itemBodyList .contentItemBodyList li .desactivo::before {
  width: 0.5em !important;
  height: 0.5em !important;
  overflow: hidden;
  content: "";
  border-radius: 50%;
  margin-right: 0.5em;
  background: #d83f3f;
}

.bodyList ul .itemBodyList .contentItemBodyList li .activo,
.bodyList ul .itemBodyList .contentItemBodyList li .done {
  color: var(--color-success) !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bodyList ul .itemBodyList .contentItemBodyList li .info {
  color: var(--color-info) !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bodyList ul .itemBodyList .contentItemBodyList li .desactivo,
.bodyList ul .itemBodyList .contentItemBodyList li .cancel {
  color: #d83f3f !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bodyList ul .itemBodyList .contentItemBodyList li .panding {
  color: #f59323 !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bodyList ul .itemBodyList .contentItemBodyList li button,
.bodyList ul .itemBodyList .contentItemBodyList li a {
  background: transparent;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 0.5em;
}

.bodyList ul .itemBodyList .contentItemBodyList li button svg,
.bodyList ul .itemBodyList .contentItemBodyList li a svg {
  width: auto;
  height: 1.35em;
  pointer-events: none;
  fill: var(--color-text);
}

.bodyList ul .itemBodyList .contentItemBodyList li button.btnDelete svg {
  fill: #d83f3f;
}

.bodyList ul .itemBodyList .contentItemBodyList li button.btnAceptar svg {
  fill: #31a863;
}

.bodyList ul .itemBodyList .contentItemBodyList li button.btnDelete i {
  color: var(--color-error);
}

.bodyList ul .itemBodyList .contentItemBodyList li button.btnAceptar i {
  color: var(--color-success);
}

.bodyList ul .itemBodyList .contentItemBodyList li button i,
.bodyList ul .itemBodyList .contentItemBodyList li a i {
  font-size: 1.5em;
  pointer-events: none;
  color: var(--color-text);
}

.bodyList ul .itemBodyList .contentItemBodyList li button.btnDelete i {
  color: var(--color-error);
}

.bodyList ul .itemBodyList:hover {
  box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.3);
}

.noAction {
  pointer-events: none !important;
}

.countTable {
  font-size: 0.8em;
  margin-left: auto;
}

.col-1-2 {
  width: 5%;
}

.col-2-2 {
  width: 15%;
}

.col-1 {
  width: 10%;
}

.col-2 {
  width: 20%;
}

.col-3 {
  width: 30%;
}

.col-4 {
  width: 40%;
}

.col-5 {
  width: 50%;
}

.col-6 {
  width: 60%;
}

.col-7 {
  width: 70%;
}

.col-8 {
  width: 80%;
}

.col-9 {
  width: 90%;
}

.col-10 {
  width: 100%;
}

.hidden {
  display: none !important;
}

.emptyResultTable {
  width: 100%;
  height: 65vh;
  background: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.emptyResultTable span {
  font-size: 2em;
}

.loadContentTable {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes snippetLoad {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loadContentTable .snippet {
  width: 5em;
  height: 5em;
  border: 4px solid var(--color-primary);
  margin-bottom: 1em;
  border-radius: 50%;
  border-left: 4px solid var(--color-text);
  animation: snippetLoad 1s ease-out;
  animation-iteration-count: infinite;
}

.paginationTable {
  position: block;
  background: var(--color-text);
  width: 30%;
  margin: 0 auto;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
}

.paginationTable .controllers {
  display: flex;
  padding: 1em;
  width: 100%;
  justify-content: center;
}

.paginationTable .controllers button {
  margin: 0em 1em;
  background: var(--color-primary);
  cursor: pointer;
}

.paginationTable .controllers button svg {
  fill: var(--color-text);
}

.paginationTable .controllers span {
  color: var(--color-primary);
}

.clearFilterButton {
  font-family: inherit;
  font-size: 1em;
  border-radius: 5px;
  padding: 0.4em 1em;
  border: none;
  color: var(--color-primary);
  background: var(--color-text);
  transition: all 0.3s;
  cursor: pointer;
}

.dropDownFilter {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  align-items: center;
}

.dropDownFilter label {
  margin-bottom: 1em !important;
}

.react-calendar {
  background-color: var(--color-primary);
}

.react-calendar__tile--now {
  background-color: var(--color-primary-variant);
}

.react-calendar__tile--active {
  background: var(--color-primary-variant);
  color: var(--color-text);
}

.react-calendar__tile--now:hover {
  background-color: var(--color-primary-variant);
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  color: var(--color-text);
}

.box-fechaExoneracion .react-date-picker {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 6px;
}

.box-fechaExoneracion .react-date-picker__inputGroup {
  width: 100%;
  background-color: var(--color-primary);
  height: 40px;
}

.box-fechaExoneracion .react-date-picker__inputGroup input {
  padding: 0em 0.3em !important;
  margin-top: 0 !important;
}

.react-daterange-picker__wrapper {
  border-radius: 5px !important;
  border: none !important;
  background: var(--color-primary-variant);
  color: var(--color-text);
  margin-top: 0.5em;
  padding: 0.5em 0.8em;
  font-size: 1em;
}

.react-daterange-picker {
  width: 100%;
}

.modalContent .column .sliderCurrentImage {
  border-radius: 10px;
  width: 100%;
}

.toggledFilters {
  margin-right: 0.5em;
  background: var(--color-text);
  border: none;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;
}

.toggledFilters i::before {
  color: var(--color-primary);
  font-size: 1.2em;
  margin: 0;
}

.toggledFilters svg {
  fill: var(--color--primary);
  width: 1.5em;
  height: 1.5em;
  margin: 0;
}

.react-daterange-picker__wrapper input,
.react-daterange-picker__wrapper button {
  padding: 0em 0.3em !important;
  margin-top: 0em !important;
  background: transparent !important;
  font-size: 0.8em !important;
  background: var(--color-primary);
}

.dropDownFilter.toggled .togglebled {
  display: none;
}

.toggledFilters i {
  pointer-events: none;
}

.toggledFilters.toggled {
  transform: rotate(180deg);
}

.toggledFilters svg {
  pointer-events: none;
}

.react-daterange-picker__button:enabled:hover
  .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
  .react-daterange-picker__button__icon {
  stroke: black !important;
}

.notFound {
  height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.notFound h2 {
  color: black !important;
  margin-bottom: 0em;
}

.notFound img {
  height: 50%;
}

.notFound p {
  width: 50%;
  margin-bottom: 1em;
}

.notFound a {
  text-decoration: none;
  background: black;
  color: white;
  padding: 0.8em 1em;
  border-radius: 5px;
}

.css-yk16xz-control {
  background: var(--color-primary) !important;
  border: none !important;
  margin-top: 0.5em !important;
}

.css-yk16xz-control .css-1wa3eu0-placeholder {
  color: var(--color-text) !important;
}

.css-yk16xz-control .css-1rhbuit-multiValue {
  background: var(--color-primary-variant) !important;
}

.css-yk16xz-control .css-1rhbuit-multiValue div {
  color: var(--color-text);
}

.css-1pahdxg-control {
  border: none !important;
  box-shadow: none !important;
  background: var(--color--primary) !important;
  margin-top: 0.5em;
}

.clearFormLineaDetalle {
  margin-left: auto !important;
  margin-right: 0em;
  font-size: 0.9em;
  text-decoration: underline;
  display: inline-block;
  background: transparent;
  border: none;
  font-family: inherit;
  color: var(--color-text);
  cursor: pointer;
}

.loadPDF {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@keyframes snippetLoadPDF {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loadPDF .snippet {
  width: 7.5em;
  height: 7.5em;
  border: 6px solid #888;
  border-left: 6px solid black;
  border-radius: 50%;
  animation: snippetLoad 1s ease-out;
  animation-iteration-count: infinite;
}

.loadPDF .porcent {
  position: absolute;
  font-size: 1em;
  color: black;
}

.loadPDF + div {
  display: none;
}

.loadPDF.hidden + div {
  display: flex;
}

.container-error {
  background-color: var(--color-primary);
  border-radius: 10px;
  width: 95%;
  padding: 0 10px;
  margin: auto;
}

.errors-denied {
  display: block;
  font-size: 16px;
  width: 88%;
  padding-left: 30px;
  padding-bottom: 10px;
}

.terminosCondiciones {
  height: 100%;
  width: 100%;
  display: block;
  margin: 5em;
  color: white;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.terminosCondiciones h2 {
  color: black !important;
  margin-bottom: 0em;
}

.terminosCondiciones img {
  height: 50%;
}

.terminosCondiciones p {
  width: 100%;
  margin-bottom: 1em;
}

.terminosCondiciones a {
  text-decoration: none;
  background: black;
  color: white;
  padding: 0.8em 1em;
  border-radius: 5px;
}

@media screen and (max-width: 1024px) {
  .listSection {
    padding: 0em 1em 0em 1em;
  }
  .bodyList ul .itemBodyList {
    overflow-x: scroll;
  }
  .bodyList ul .itembody {
    overflow-x: visible;
  }
  .navSection {
    gap: 0.5rem;
    align-items: end;
    padding: 0.4em 0.4em;
  }
  .dropDownFilter {
    width: 60% !important;
    align-items: end;
    font-size: 14px;
  }
  .dropDownFilter label {
    margin-bottom: 0 !important;
    width: 25% !important;
    padding: 0 4px;
  }
}

@media screen and (max-width: 768px) {
  .navSection {
    flex-wrap: wrap;
    z-index: 800 !important;
  }
  .dropDownFilter {
    width: 82% !important;
  }
  .addContent {
    margin-left: 0;
    width: 100% !important;
    padding: 10px 20px;
  }
  .listSection ul li {
    font-size: 12px;
  }
  .bodyList ul .itemBodyList .contentItemBodyList {
    overflow: visible;
    width: max-content;
  }

  .bodyList ul .itemBodyList .contentItemBodyList li {
    min-width: 45px;
  }
  .bodyList ul .itemBodyList .contentItemBodyList .moneda {
    margin-left: 20px !important;
  }
}

@media screen and (max-width: 576px) {
  .listSection ul li {
    font-size: 10px;
  }
  .navSection .sortBy {
    justify-content: space-between;
    align-items: end;
  }
  .navSection .sortBy label {
    width: 33% !important;
  }

  .bodyList ul .itemBodyList .contentItemBodyList .moneda {
    margin-left: 20px !important;
  }
}

@media screen and (max-width: 468px) {
  .listSection ul li {
    font-size: 9px;
  }

  .bodyList ul .itemBodyList .contentItemBodyList .moneda {
    margin-left: 20px !important;
  }
}
