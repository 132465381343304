.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}

.App.fontSize18 {
  font-size: 18px !important;
}

.App.fontSize16 {
  font-size: 16px !important;
}

.App.fontSize14 {
  font-size: 14px !important;
}

.App.fontSize12 {
  font-size: 12px !important;
}

.mainViewer {
  width: 82%;
  background: var(--color-primary-variant);
  height: 100%;
  transition: all 0.15s;
  overflow: hidden;
}

.mainViewer .router {
  color: var(--color-text);
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainViewer .router h2 {
  color: var(--color-text);
  width: 100%;
  font-size: 2em;
  text-align: center;
}

.Toastify__toast-container--top-right {
  bottom: 1em !important;
  right: 1em !important;
  top: auto !important;
  font-family: inherit;
  width: auto !important;
  max-width: 50% !important;
  z-index: 1000000000 !important;
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  font-family: inherit !important;
}

.mainViewerLogin {
  width: 100%;
  height: 100vh;
  background: var(--color-primary-variant);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadContent {
  position: fixed;
  bottom: 1em;
  display: flex;
  align-items: center;
  left: 22%;
  background: white;
  box-sizing: border-box;
  padding: 0.2em 2em;
  border-radius: 5px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
}

@keyframes snippetLoad {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loadContent .snippet {
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  border: 5px solid black;
  border-left: 5px solid #555;
  border-radius: 50%;
  animation: snippetLoad 1s ease-out;
  animation-iteration-count: infinite;
  position: relative;
}

.loadContent span.porcent {
  position: absolute;
  transform: rotate(0deg);
  font-size: 0.7em;
  display: block;
  margin-left: 1em;
}

.movilScreen {
  display: none !important;
  width: 100%;
  /* height: 100vh; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}

.movilScreen .navRegistro {
  border-bottom: 1px solid black;
}

.movilScreen .mainMovilScreen {
  width: 90%;
  max-width: 750px;
  margin: 0em auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em;
  box-sizing: border-box;
}

.movilScreen .mainMovilScreen h3 {
  font-size: 2em;
  margin: 0px 0px;
  font-weight: bold;
}

.movilScreen .mainMovilScreen p {
  font-size: 1em;
  text-align: left;
}

.movilScreen .mainMovilScreen img {
  width: 90%;
  height: auto;
}

.movilScreen .mainMovilScreen a {
  background: #0dac22 !important;
  color: white;
  padding: 0.5em;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
  border-radius: 1em 1em 0em 0em;
  position: fixed;
  width: 100%;
  bottom: 0em;
  left: 0em;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 1em;
}

.movilScreen .mainMovilScreen a i {
  color: white;
  margin-left: 1em;
  font-size: 2em !important;
}

.gridMovilScreen {
  width: 90%;
  max-width: 750px;
  grid-gap: 3em;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 10em;
  margin-top: 4em;
}

.gridMovilScreen img {
  width: 100%;
  height: auto;
  border-radius: 1em;
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

@media screen and (max-width: 1200px) {
  /* .App {
    display: none;
  } */

  .movilScreen {
    display: flex !important;
  }
}

@media screen and (max-width: 1024px) {
  .mainViewer {
    width: 100%;
  }
}

@media screen and (max-width: 780px) {
  .movilScreen .mainMovilScreen h3 {
    font-size: 1.5em;
    margin: 0px 0px;
    font-weight: bold;
  }

  .gridMovilScreen {
    grid-template-columns: 1fr 1fr;
  }
}

*,
*::after,
*::before {
  color: var(--color-text);
}

.react-calendar {
  background-color: var(--color-primary);
}

.modal-factura {
  position: absolute;
  width: 500px;
  height: 300px;
  background-color: var(--color-primary-variant);
  z-index: 99999;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  border-radius: 10px;
}

.close-modal-fac {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--color-primary);
  color: var(--color-text);
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-modal-fac {
  width: 150px;
  background-color: var(--color-primary);
  color: var(--color-text);
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 10px;
}

.title-modal-fac {
  margin-left: 20px;
}

.text-modal-fac {
  text-align: center;
  margin: 0;
}

.container-title-modal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
