.FormContainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  right: 0%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: slideForm 200ms forwards linear;
}

@keyframes slideForm {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}

.FormContainer .Form {
  background: var(--color-primary-variant);
  width: 90%;
  max-width: 1240px;
  height: 90vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.FormContainer .Form .FormContent {
  height: calc(100% - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.FormContainer .Form .FormContent h4 {
  font-size: 2.5em;
  color: var(--color-text);
}

.FormContainer .Form .FormTopBar h4 {
  font-size: 1.3em;
  font-weight: 500;
}

.FormTopBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0em 2em;
  background: var(--color-primary);
  border-radius: 10px;
}

.FormTopBar .FormTitle h4 {
  font-size: 1.3em;
  font-weight: 500;
}

.optionsFormTopBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.optionsFormTopBar label {
  font-size: 0.7em !important;
  margin-right: 2em;
  display: flex;
  flex-direction: column;
}

.optionsFormTopBar a {
  line-height: 0;
}

.optionsFormTopBar label input {
  margin-top: 0.3em !important;
  width: 100px;
  border-radius: 5px;
  border: none;
  padding: 0.3em 1em;
  font-size: 0.9em !important;
}

.optionsFormTopBar button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.optionsFormTopBar button svg,
.optionsFormTopBar a svg {
  width: auto;
  height: 1.8em;
  fill: var(--color-text);
  margin-left: 1em;
}

.optionsFormTopBar button i,
.optionsFormTopBar a i {
  font-size: 1.5em;
  color: var(--color-text);
  margin-left: 1em;
}

.optionsFormTopBar button.closeForm svg {
  width: auto;
  height: 1.5em;
}

.optionsFormTopBar button.closeForm i {
  font-size: 1.5em;
  color: var(--color-text);
}

.optionsFormTopBar button.closeForm {
  outline: none;
}

.BackSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0;
  border: none;
  cursor: pointer;
  font-family: inherit;
}

.BackSection h4 {
  font-size: 1.3em;
  font-weight: 500;
}

.BackSection svg {
  transform: rotate(90deg);
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.8em;
}

.workspace {
  width: 100%;
  height: 100%;
  display: flex;
}

.workspace .sidebarForm {
  width: 25%;
  border-left: 1px solid var(--color-border);
  overflow-y: scroll;
}

.workspace .sidebarForm::-webkit-scrollbar-thumb,
.workspace .mainForm .sectionForm::-webkit-scrollbar-thumb {
  width: 0.4em;
  background: var(--color--primary);
  border-radius: 2em;
}

.workspace .sidebarForm::-webkit-scrollbar,
.workspace .mainForm .sectionForm::-webkit-scrollbar-thumb {
  width: 0.4em;
}

.workspace .mainForm {
  width: 75%;
}

.workspace .sidebarForm .actionForm {
  border-bottom: 1px solid var(--color-border);
  padding: 0.5em 1em;
  height: 50px;
  display: flex;
  justify-content: space-around;
  position: sticky;
  top: 0;
  background: var(--color-primary-variant);
}

.workspace .sidebarForm .saveForm {
  width: 45%;
  height: 35px;
  border: none;
  background: var(--color-success);
  color: white;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  font-family: inherit;
  font-size: 0.9em;
  margin: 0em 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workspace .sidebarForm .generarForm i,
.workspace .sidebarForm .saveForm i {
  color: white;
  margin-right: 0.5em;
}

.workspace .sidebarForm .generarForm {
  padding: 0.8em;
  width: 45%;
  border: none;
  background: var(--color-info);
  color: white;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  font-family: inherit;
}

.buttonForm {
  margin-top: 6px;
}

.workspace .sidebarForm label.text {
  box-sizing: border-box;
  padding: 0.8em 1em;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
}

.workspace .sidebarForm label.text input,
.workspace .sidebarForm label.text select,
label.text textarea {
  padding: 0.8em 0.8em;
  border-radius: 5px;
  background: var(--color-primary);
  border: none;
  margin-top: 0.5em;
  font-size: 1em;
  font-family: inherit;
  outline: none;
}

.workspace .sidebarForm label.checkbox {
  font-size: 0.8em;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.workspace .sidebarForm label.checkbox input {
  margin-left: 2em;
}

.workspace .mainForm {
  display: flex;
  align-content: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
}

.workspace .mainForm label {
  box-sizing: border-box;
  margin-bottom: 0em;
  padding: 0.8em 1em;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
}

.workspace .mainForm label input {
  padding: 0.8em 0.8em;
  border-radius: 5px;
  background: var(--color-primary) !important;
  color: var(--color-text) !important;
  border: none;
  margin-top: 0.5em;
  font-size: 1em;
  font-family: inherit;
  outline: none;
}

.workspace .mainForm label select {
  padding: 0.8em 1em;
  border-radius: 5px;
  background: var(--color-primary);
  border: none;
  margin-top: 0.5em;
  font-size: 1em;
  font-family: inherit;
  outline: none;
  color: var(--color-text);
}

.searchForm {
  width: 100%;
  display: flex;
  align-items: center;
}

.searchForm input {
  width: 100%;
  border-radius: 5px 0px 0px 5px !important;
}

.searchForm button svg {
  width: 1.3em;
  height: 1.3em;
  fill: var(--color-primary);
}

.searchForm button {
  padding: 0.8em 0.8em;
  border-radius: 0em 5px 5px 0em;
  background: var(--color-text);
  border: none;
  margin-top: 0.5em;
  color: var(--color-primary);
}

.searchForm button i::before {
  color: var(--color-primary);
}

input:disabled {
  background: var(--color-primary) !important;
  opacity: 0.8;
  color: var(--color-border);
}

.navigationForm {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  height: 50px;
  padding: 0.5em 1em;
}

.navigationForm button {
  border-radius: 5px;
  border: none;
  padding: 0.4em;
  font-size: 0.85em;
  font-family: inherit;
  cursor: pointer;
  margin-right: 1.5em;
  background: var(--color-text);
  color: var(--color-primary);
}

.navigationForm button.active {
  background: var(--color-primary);
  font-weight: 700;
  color: var(--color-text);
}

.FormProducto .mainForm {
  padding: 0em;
}

.FormProducto .mainForm .sectionForm {
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  padding: 0.5em;
  padding-bottom: 1em;
  box-sizing: border-box;
  justify-content: space-between;
  align-content: flex-start;
  scroll-behavior: auto;
}

.FormProducto .mainForm .sectionForm .listSection {
  width: 100%;
  margin: auto;
}

.FormProducto .mainForm .sectionForm .listSection .emptyResultTable {
  height: 350px;
  background: var(--color-primary);
  font-size: 0.8em;
}

.titleSectionForm {
  padding-left: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleSectionForm span {
  font-size: 0.8em;
  color: var(--color-text);
}

.titleSectionForm h3 {
  font-weight: 500;
  font-size: 1.5em;
  margin: 0.5em 0em;
}

.navigationForm .searchGeneral.active {
  background: var(--color-primary);
  height: 2.5em;
}

.navigationForm .searchGeneral.active input {
  padding: 0.8em 0.2em !important;
}

.navigationForm .searchGeneral.active svg {
  fill: white;
}

.navigationForm .searchGeneral.active input {
  color: white !important;
  font-size: 0.9em;
}

.navigationForm .searchGeneral.active input::placeholder {
  color: white;
}

.cabySelecionadoList {
  width: 100%;
  padding: 0;
  background: var(--color-primary);
  border-radius: 10px;
}

.cabySelecionadoList li {
  list-style: none;
  box-sizing: border-box;
  padding: 1em;
  display: flex;
  justify-content: space-between;
}

.cabySelecionadoList li span {
  display: inline-block;
  width: 50%;
  font-size: 0.8em;
  text-align: end;
}

.cabySelecionadoList li b {
  font-size: 0.8em;
}

.cabySelecionadoList li:nth-child(2n) {
  background: var(--color-primary-variant);
}

.mainForm .pagination {
  background: var(--color-text);
  border-radius: 8px 8px 0px 0px;
  height: 70px;
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  position: sticky;
  bottom: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainForm .pagination .controllers {
  display: flex;
}

.mainForm .pagination .controllers span {
  margin: 0em 1em;
  color: var(--color-primary);
}

.mainForm .listSection {
  padding: 1em 0em 1em 0em;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.previousPage {
  background: var(--color-primary);
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.previousPage svg {
  width: 1em;
  fill: var(--color-text);
  transform: rotate(90deg);
}

.nextPage {
  background: var(--color-primary);
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.nextPage svg {
  width: 1em;
  fill: var(--color-text);
  transform: rotate(-90deg);
}

.contentItemBodyList.selected {
  background: var(--color-primary-variant) !important;
}

.contentItemBodyList.selected li {
  color: black;
}

.contentItemBodyList.selected li b {
  color: var(--color-text);
}

.contentItemBodyList.selected li button svg {
  fill: white !important;
}

.dropDownSection {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1em 0.5em;
  cursor: pointer;
}

.dropDownSection.toggled button {
  transform: rotate(180deg) !important;
}

.dropDownSection span {
  background: var(--color-text);
  color: var(--color-primary);
  padding: 0.5em 1em;
  border-radius: 2em;
  pointer-events: none;
}

.dropDownSection hr {
  height: 1px;
  width: 70%;
  border: none;
  background: var(--color-border);
  pointer-events: none;
}

.dropDownSection button {
  background: var(--color-text);
  border: none;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.dropDownSection button i::before {
  font-size: 1em;
  color: var(--color-primary);
}

.actionsSection {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.actionsSection button {
  margin-left: 0;
  margin-right: 1em;
}

.actionsSection .searchGeneral {
  margin-right: 1em !important;
  background: var(--color-primary);
}

.dropDownItem {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.btnForm {
  background: var(--color-text);
  color: var(--color-primary);
  align-self: flex-end;
  margin-bottom: 0.8em;
  padding: 0.8em 0.5em;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
}

.btnForm:hover {
  opacity: 0.8;
}

.btnForm i::before {
  color: var(--color-primary);
  font-size: 1em;
  margin-right: 0.5em;
}

.btnForm svg {
  width: 18px;
}

.css-1fhf3k1-control {
  border-color: transparent !important;
}

.inputEditable {
  margin-left: 0em !important;
  margin-top: 0.5em !important;
  padding: 0.8em 0em !important;
  height: 3em !important;
  background: var(--color-primary) !important;
}

.inputEditable input {
  width: 100% !important;
  padding: 0.8em 0.5em !important;
  margin-top: 0em !important;
}

.inputEditable .optionsSearchGeneral button {
  padding: 0.5em !important;
  background: var(--color-primary);
}

.totalesEmpty {
  width: 100%;
  background: var(--color-primary);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 1em;
  margin-top: 1em;
}

.totalesEmpty h4 {
  font-size: 1.1em !important;
  text-align: center;
  color: var(--color-text) !important;
  font-weight: 500;
}

.totalesEmpty button {
  background: var(--color-text) !important;
  color: var(--color-primary) !important;
  font-family: inherit;
  border: none;
  border-radius: 5px;
  padding: 0.5em 1em;
  cursor: pointer;
}

.statusReader:disabled {
  background: var(--color-success) !important;
  color: white;
}

.statusGenerado:disabled {
  background: var(--color-info) !important;
  color: white;
}

.statusAnulado:disabled {
  background: var(--color-error) !important;
  color: white;
}

.css-14jk2my-container {
  margin-top: 0.5em;
  background: var(--color--primary) !important;
  border-radius: 5px;
}

.css-1fhf3k1-control {
  background: var(--color-primary) !important;
}

.css-26l3qy-menu {
  background: var(--color--primary) !important;
  opacity: 1;
}

.css-2b097c-container {
  background: var(--color-primary);
  color: var(--color-text) !important;
}

.css-1n7v3ny-option {
  background: var(--color-text) !important;
  color: var(--color-primary) !important;
}

.css-26l3qy-menu {
  background: var(--color-primary) !important;
}

.css-2b097c-container::placeholder {
  color: var(--color-text) !important;
}

.css-tlfecz-indicatorContainer {
  color: var(--color-text) !important;
}

.css-1pahdxg-control {
  background: var(--color--primary) !important;
  color: var(--color-text) !important;
}

.css-gg45go-NoOptionsMessage {
  color: var(--color-text) !important;
}

.css-1rhbuit-multiValue {
  background: var(--color-primary-variant) !important;
  color: var(--color-text) !important;
}

.css-12jo7m5,
.css-1gtu0rj-indicatorContainer {
  color: var(--color-text) !important;
}

.anularAction {
  background: var(--color-error) !important;
  border-bottom: 7px solid var(--color-error) !important;
}

.renviarAction {
  background: var(--color-info) !important;
  border-bottom: 7px solid var(--color-info) !important;
}

.navigationForm .setOptions span {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.8rem;
}

.navigationForm .setOptions span:hover {
  color: var(--color-text);
}

.navigationForm .setOptions span svg {
  height: 1.5em;
  width: auto;
  fill: var(--color-text);
}

.navigationForm .setOptions {
  display: flex;
  margin-left: auto;
}

.navigationForm .setOptions a {
  font-size: 0.7em;
  display: inline-flex;
  margin-right: 1em;
  flex-direction: column;
  background: transparent;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  text-decoration: none;
}

@media screen and (max-width: 874px) {
  .FormContainer .Form {
    width: 95%;
  }
  .list-noscroll {
    overflow-x: hidden !important;
  }
  .FormProducto .mainForm .sectionForm {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .btnForm {
    width: 100% !important;
  }
  .workspace .mainForm label {
    padding: 4px;
    width: 95% !important;
  }
  .workspace .mainForm .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .workspace .mainForm {
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: auto;
  }
  .FormProducto .mainForm .sectionForm label {
    width: 90% !important;
    padding: 0;
  }
  .FormProducto .mainForm .sectionForm .listSection {
    min-height: 150px !important;
    height: max-content !important;
    margin: 0;
    overflow: scroll;
  }
  .listSection .headList ul {
    /* overflow-x: scroll; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
  }
  .cabySelecionadoList {
    font-size: 11px;
  }
  .titleSectionForm {
    font-size: 12px;
  }
  .workspace .mainForm {
    width: 65%;
  }
  .workspace .sidebarForm {
    width: 35%;
  }
  .workspace .sidebarForm .saveForm {
    width: 100%;
  }
  .navigationForm button {
    margin-right: 0;
  }
  .searchGeneral {
    width: 100% !important;
  }
  .searchGeneral .optionsSearchGeneral {
    font-size: 12px;
    text-wrap: nowrap;
  }
  .navigationForm {
    flex-wrap: wrap;
    height: max-content;
    gap: 4px;
    padding: 0.2em;
    justify-content: space-around;
  }
  .workspace .sidebarForm .generarForm {
    width: 100%;
  }
  .navigationForm .searchGeneral.active {
    display: flex;
    flex-direction: row;
    gap: 2px;
  }
  .navigationForm .searchGeneral.active input {
    font-size: 12px;
  }
  .workspace .sidebarForm .generarForm .buttonForm {
    display: flex;
    margin-top: 0;
    flex-direction: row;
  }
  .navigationForm button {
    font-size: 12px;
  }
}

@media screen and (max-width: 574px) {
  .workspace .mainForm {
    width: 60%;
  }
  .workspace .sidebarForm {
    width: 40%;
  }
  .workspace .sidebarForm label.text {
    font-size: 0.6em;
  }
  .titleSectionForm,
  .actionsSection {
    font-size: 11px;
  }
  .workspace .mainForm label {
    font-size: .7em;
  }
}
