.sectionForm {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 5rem;
}

.formCompaniaDetalles {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.25rem;
}

.formComprobantes {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.detalles > * {
  width: 100%;
}

.span2 {
  grid-column: span 2;
}

.span3 {
  grid-column: span 3;
}

@media screen and (max-width: 874px) {
  .formCompaniaDetalles {
    display: flex;
    flex-direction: column;
  }
  .formCompaniaDetalles label {
    padding: 0;
  }
}
