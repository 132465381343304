.Escritorio {
  height: calc(100% - 6em);
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 1em;
  padding-top: 1em;
  box-sizing: border-box;
}

.actionsButtons {
  width: 95%;
  margin: auto;
  overflow-x: scroll;
}

.actionsButtons::-webkit-scrollbar {
  height: 0px;
}

.actionsButtons button {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding: 0.5em 1em;
  border-radius: 2em;
  white-space: nowrap;
  background: var(--color-primary-variant);
  color: var(--color-text);
  cursor: pointer;
}

.actionsButtons button i {
  margin-right: 4px;
  font-size: 1em;
}

.actionsButtons .carrusel {
  display: flex;
}

.Escritorio .mainEscritorio {
  width: 60%;
  margin-bottom: 0em;
}

.Escritorio .sidebarEscritorio {
  width: 35%;
  margin-bottom: 0em;
}

.elementEscritorio {
  width: 100%;
  background: white;
  border-radius: 10px;
  min-height: 100px;
  margin-bottom: 1em;
}

.bannerAnucio {
  /* background: black; */
  display: flex;
  color: black;
  box-sizing: border-box;
  align-items: center;
  padding: 1em 2em;
}

.bannerAnucio .info {
  width: 60%;
}

.bannerAnucio .info h3 {
  font-size: 1.4em;
  margin-bottom: 0.5em !important;
  margin-top: 0em !important;
  color: black;
}

.bannerAnucio .info p {
  font-size: 0.85em;
  margin-top: 0em;
  color: black;
}

.bannerAnucio .info button {
  border-radius: 5px;
  background: black;
  padding: 0.5em 1em;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 0.95em;
  color: white;
}

.bannerAnucio .image {
  width: 40%;
}

.bannerAnucio .image img {
  width: 100%;
  border-radius: 5px;
}

.documentosRebibidos.elementEscritorio {
  background: var(--color-primary);
}

.statusDocumentos.elementEscritorio {
  height: 40%;
}

.Escritorio::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Escritorio::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.elementEscritorio {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}

.elementEscritorio::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.elementEscritorio::-webkit-scrollbar {
  background: transparent;
  width: 0.3em;
}

.elementEscritorio .sectionNotification .itemsSectionNotification .item {
  width: 100%;
}
.labelElementEscritorio {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.labelElementEscritorio .count {
  display: inline-block;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  font-weight: bold;
  background: rgb(197, 18, 18);
  color: white;
  border-radius: 50%;
  pointer-events: none;
}

.elementEscritorio a.more {
  margin: auto;
  margin-bottom: 1em;
  text-align: center;
  width: 80px;
  display: block;
  background: black;
  color: white;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: 0.9em;
}

.statusContentMain.elementEscritorio {
  display: flex;
  flex-wrap: wrap;
  padding: 1em 0em;
  justify-content: space-around;
  flex-wrap: wrap;
  background: var(--color-primary);
}

.statusContentMain.elementEscritorio hr {
  width: 95%;
  background: #aaa;
  border: none;
  height: 0.5px;
}

.statusContentMain.elementEscritorio .statusContent {
  width: 30%;
  margin-bottom: 1em;
}

.statusContentMain.elementEscritorio .statusContent h4 {
  font-weight: bold;
  font-size: 1em;
  color: var(--color-text);
  margin: 6px 0px;
}

.moreDetails {
  display: block;
  font-size: 0.8em;
  color: var(--color-text);
  margin-top: 0.5em;
  text-decoration: underline;
}

.statusContent .content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* font-size: 0.9em; */
  color: var(--color-text);
}

.statusContent .content b {
  font-size: 1.4em !important;
  color: var(--color-text) !important;
}

.statusContent .content i {
  font-size: 2em !important;
  color: var(--color-text);
  margin-right: 0.5em;
}

.DocumentosRecibidosResumen .contentItemBodyList {
  display: flex;
  flex-wrap: wrap;
}

.DocumentosRecibidosResumen .bodyList {
  padding-bottom: 0em;
}

.DocumentosRecibidosResumen .bodyList ul .itemBodyList .contentItemBodyList li {
  margin: 0em;
}

.DocumentosRecibidosResumen {
  overflow-y: visible !important;
}

@media screen and (max-width: 1240px) {
  .Escritorio {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    justify-content: flex-start;
    gap: 5px;
  }
  .Escritorio .mainEscritorio {
    width: 90%;
    
  }
  .Escritorio .sidebarEscritorio {
    width: 90%;
  }
  .ControllerChartsDashboard label {
    width: 33%;
  }
  .statusContentMain.elementEscritorio .statusContent .content {
    font-size: 0.8em;
    text-wrap: balance;
  }
}

@media screen and (max-width: 768px) {
  .statusContentMain.elementEscritorio .statusContent .content {
    font-size: 0.5em;
    text-wrap: balance;
  }
}

.carousel img {
  height: 50%;
}

.carousel p {
  width: 100%;
  margin-bottom: 1em;
}

.carousel a {
  text-decoration: none;
  background: black;
  color: white;
  padding: 0.8em 1em;
  border-radius: 5px;
}
