.Sidebar {
  width: 18%;
  height: 100vh;
  height: 100dvh;
  background: var(--color-primary);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  transition: all 0.3s !important;
  font-size: 0.9em;
}

.Sidebar::-webkit-scrollbar {
  background: var(--color-primary);
  width: 0.5em;
}

.Sidebar::-webkit-scrollbar-thumb {
  background: var(--color-primary-variant);
  border-radius: 2em;
  margin-right: 1em;
}

.Sidebar h2 {
  color: var(--color-primary-variant);
}

.Sidebar .logo {
  width: 70%;
}

.Sidebar .logo a {
  display: block;
  width: 100%;
}

.Sidebar .logo a img {
  width: 100%;
  height: auto;
}

.menuSideBar {
  width: 100%;
  padding: 0;
}

.menuSideBar li {
  margin-bottom: 1em;
}

.menuSideBar li a {
  color: var(--color-text);
  text-decoration: none;
  font-size: 1em !important;
  transition: all 0.3s;
  width: 100%;
  display: inline-block;
}

.menuSideBar .itemMenuSidebar,
.menuSideBar .itemMenuSidebarWithList {
  margin-bottom: 0.5em;
  outline: none;
}

.menuSideBar .itemMenuSidebarWithList button:hover,
.menuSideBar .itemMenuSidebarWithList button:focus {
  font-weight: bold;
  color: var(--color-text) !important;
  background: var(--color-primary-variant) !important;
  border-radius: 10px;
  outline: none;
}

.menuSideBar .itemMenuSidebar a:focus,
.menuSideBar .itemMenuSidebar a:focus {
  color: var(--color-text);
  outline: none;
}

.menuSideBar .itemMenuSidebar a {
  box-sizing: border-box;
  padding: 0.5em 1em;
  outline: none;
}

.menuSideBar .itemMenuSidebar a:hover,
.menuSideBar .itemMenuSidebar a:focus {
  font-weight: bold;
  color: var(--color-text) !important;
  background: var(--color-primary-variant) !important;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
}

.menuSideBar .itemMenuSidebarWithList button:hover svg,
.menuSideBar .itemMenuSidebarWithList button:focus svg {
  fill: var(--color-text) !important;
}

.menuSideBar .itemMenuSidebarWithList ul li a:hover,
.menuSideBar .itemMenuSidebarWithList ul li a:focus,
.menuSideBar .itemMenuSidebarWithList ul li button:hover,
.menuSideBar .itemMenuSidebarWithList ul li button:focus {
  outline: none;
  font-weight: 600;
}

.menuSideBar li button {
  background: transparent;
  font-family: inherit;
  color: var(--color-text);
  border: 0;
  font-size: 1em !important;
  padding: 1em;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.menuSideBar li button svg {
  width: 1em;
  height: 1em;
  fill: white;
  pointer-events: none;
}

.menuSideBar li ul {
  padding-left: 2em;
  margin-top: 0.5em;
  flex-direction: column;
  display: none;
}

.menuSideBar li button svg {
  transform: rotate(-90deg);
}

.menuSideBar .submenuToggled button svg {
  transform: rotate(360deg);
}

.submenuToggled ul {
  display: flex !important;
}

.menuSideBar li ul li {
  list-style: none;
  margin-bottom: 0.5em;
}

.menuSideBar li ul li a {
  font-size: 0.9em !important;
  margin-bottom: 0.5em;
}

.linkSubMenuActive {
  font-weight: bold;
}

.linkLevelOneActive {
  font-weight: bold;
  color: var(--color-text) !important;
  background: var(--color-primary-variant);
  display: inline-block;
  width: 100%;
  border-radius: 10px;
}

.submenuToggled {
  background: var(--color-primary-variant);
  border-radius: 10px;
  padding: 0.5em 0em;
  transition: all 0.3s;
}

.submenuToggled button {
  color: var(--color-text) !important;
  padding: 1em !important;
  font-weight: bold;
}

.submenuToggled button svg {
  fill: var(--color-text) !important;
}

.submenuToggled ul li a {
  color: var(--color-text);
}

.submenuToggled ul li a:hover {
  font-weight: bold;
}

.toggleSidebar {
  margin-top: auto;
  margin-bottom: 1em;
  display: flex;
  background: transparent;
  width: 90%;
  border: none;
  color: var(--color-text);
  font-size: 1em;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.toggleSidebar .icon {
  margin-right: 1em;
  background: var(--color-primary-variant);
  padding: 0.5em;
  border-radius: 50%;
  display: flex;
  transition: all 0.3s;
  pointer-events: none;
}

.toggleSidebar .icon svg {
  width: 1em;
  height: 1em;
  transform: rotate(90deg);
  transition: all 0.3s;
  fill: var(--color-text);
  pointer-events: none;
}

.Sidebar.toggled {
  position: absolute;
  left: -20%;
}

.Sidebar.toggled + .mainViewer {
  width: 100%;
}

.Sidebar.toggled .toggleSidebar {
  width: max-content;
  position: fixed;
  bottom: 1em;
  left: 1em;
  z-index: 1000;
}

.Sidebar.toggled .toggleSidebar .icon {
  background: var(--color-text);
  pointer-events: none;
}

.Sidebar.toggled .toggleSidebar .icon svg {
  fill: var(--color-primary);
  transform: rotate(-90deg);
}

.Sidebar.toggled .toggleSidebar span {
  display: none;
}

@media screen and (max-width: 1024px) {
  .Sidebar {
    width: 50%;
    position: absolute;
    transition: all .5s ease-in-out !important;
    z-index: 100;
  }
  .Sidebar.toggled {
    position: absolute;
    transition: all .5s ease-in-out !important;
    left: -50%;
  }
}

@media screen and (max-width: 768px) {
  .menuSideBar li {
    font-size: 14px;
  }
  .Sidebar {
    z-index: 900 !important;
  }
}

@media screen and (max-width: 576px) {
  .menuSideBar li {
    font-size: 12px;
  }
}
