.ChartDashboard{
    width: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-primary-variant);
    border-radius: 16px;
    margin-top: 8px;
    color: #e7e7e7 !important;
    margin-bottom: 8px;
}