.Usuarios {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 2em;
}
input[type='text']::placeholder {
  color: #ccc;
}
.Usuarios::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Usuarios::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}
.containerMain {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inner-div {
  flex-basis: 49%;
}

.containerMain {
  margin: 20px;
}
.keyViewUser {
  width: 50%;
}
.textForm {
}
.react-datepicker__navigation {
  align-items: center !important;
  background: none;
  display: flex !important;
  justify-content: center !important;
  text-align: center;
  cursor: pointer !important;
  position: absolute;
  top: 2px;
  padding: 0 !important;
  border: none !important;
  z-index: 1;
  height: 32px;
  width: 32px !important;
  text-indent: -999em;
  overflow: hidden;
  border-radius: 0px !important;
  background-color: transparent !important;
}
.react-datepicker-wrapper input {
  width: 100% !important;
}

@media screen and (max-width: 1024px) {
  .keyViewUser {
      width: 90%;
      height: max-content;
  }
  .containerMain {
      flex-direction: column;
      margin: 5px;
      overflow-y: auto;
  }
  .inner-div {
      display: flex;
      flex-direction: column;
  }
  .inner-div label {
      margin: 0;
      padding: 4px !important;
  }
}