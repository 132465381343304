.header {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
  padding-top: 1rem;
  margin-block-start: 0;
  margin-block-end: 1rem;
}

.clean {
  cursor: pointer;
  min-width: fit-content;
  background-color: inherit;
  border: none;
  font-size: 0.9rem;
  font-family: inherit;
  color: #888888;
  text-decoration: underline;
  transition: color 100ms linear;
}

.clean:hover {
  color: #4f4f4f;
}

.title {
  margin: 0;
  width: 100%;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: left;
}

.container {
  width: 100%;
  padding: 0.5rem;
}

.exoInfo {
  padding-inline: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.exoInfo .exoInfoItem strong {
  font-weight: 500;
  font-size: 1rem;
  display: block;
  margin-bottom: 0.25rem;
}

.exoInfo .exoInfoItem p {
  margin: 0;
}
