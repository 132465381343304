.Notificaciones {
  width: 100%;
  align-self: flex-start;
}

.Notificaciones .mainNotification {
  width: 100%;
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.Notificaciones .mainNotification .containerNotifications .sectionNotification {
  width: 100%;
}

.Notificaciones .mainNotification .containerNotifications {
  width: 100%;
  max-width: 600px;
}

.Notificaciones .mainNotification .containerNotifications .sectionNotification .itemsSectionNotification .item {
  margin: 0em;
  margin-right: 0em;
  width: 100%;
  background: var(--color-primary);
  margin-bottom: 1em;
  text-decoration: none !important;
}

.Notificaciones .mainNotification .emptyNotifications i {
  font-size: 5em;
  color: var(--color-text);
  margin-top: 1em;
}

.Notificaciones .mainNotification .emptyNotifications h4,
.Notificaciones .mainNotification .emptyNotifications p {
  color: var(--color-text);
}

.Notificaciones .mainNotification .emptyNotifications h4 {
  font-size: 1.8em;
}

.Notificaciones .mainNotification .emptyNotifications p {
  font-size: 0.8em;
}

.Notificaciones .mainNotification .containerNotifications .sectionNotification .itemsSectionNotification .item .info b {
  font-size: 1.5em;
}

.Notificaciones .mainNotification .containerNotifications .sectionNotification .itemsSectionNotification .item .info p {
  font-size: 1.2em !important;
}

.Notificaciones .mainNotification .containerNotifications .sectionNotification .itemsSectionNotification .item .date {
  font-size: 1em;
}

.Notificaciones
  .mainNotification
  .containerNotifications
  .sectionNotification
  .itemsSectionNotification
  .item
  .checkNotification {
  font-size: 1.2em;
  border: none;
}

.typeNotification {
  position: absolute;
  top: 0;
  left: -3em;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--color-text);
  color: var(--color-primary) !important;
  font-size: 1.3em;
}

.typeNotification.llavePorVencer {
  background: #f1cd00;
  color: black;
}

.typeNotification.llaveVencida,
.typeNotification.documentoRechazado {
  background: var(--color-error);
  color: white !important;
}

.typeNotification.documentoRecibido {
  background: var(--color-success);
  color: white !important;
}

.typeNotification.migracionDocumentos,
.typeNotification.documentoAceptado {
  background: var(--color-info);
  color: white !important;
}
