.loadCompleteScreen {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000;
}

@keyframes logoTtileo {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
}

.loadCompleteScreen .logo img {
  width: 400px;
  height: auto;
  animation: logoTtileo 1s ease-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.loadCompleteScreen.hiden {
  display: none;
}
