label.text {
  box-sizing: border-box;
  padding: 0.8em 1em;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
}

.validCedula {
  margin: 1em 0em !important;
}

.validCedulaInput {
  width: 300px;
  font-size: 1.2em !important;
}

.validcedulaSlide .steps span {
  font-size: 1.2em;
}

.validcedulaSlide h4,
.validcedulaSlide p {
  text-align: center;
  width: 450px !important;
  margin-bottom: 0.5em !important;
}

.validcedulaSlide h4 {
  font-size: 1.8em !important;
}

.validcedulaSlide p {
  font-size: 1em !important;
}

.validcedulaSlide label {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.validcedulaSlide {
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  align-content: center !important;
}

label.text input,
label.text select,
label.text textarea {
  padding: 0.8em 0.8em;
  border-radius: 5px;
  background: #eee;
  border: none;
  margin-top: 0.5em;
  font-size: 1em;
  font-family: inherit;
  outline: none;
}

.registroForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.registroForm h4 {
  width: 100%;
}

.registroForm button {
  margin-bottom: 1em;
}

.cardData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

/* --------------- */

.Registro {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
  background: white;
}

.Registro .FormLoginContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0em 3em;
  box-sizing: border-box;
  margin: auto;
}

.Registro .FormLoginContainer .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Registro .FormLoginContainer .logo img {
  width: 300px;
  height: auto;
}

.Registro .FormLoginContainer .titleRegistro {
  font-size: 2em;
  margin-bottom: 0em;
}

.Registro .FormLoginContainer .Form {
  background: white;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1em;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  height: 80vh;
}

.Registro .FormLoginContainer .Form {
  box-shadow: none !important;
  min-height: 100vh;
  height: auto !important;
}

.Registro .FormLoginContainer .Form .carrusel {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.4s;
}

.Registro .FormLoginContainer .Form .slide {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 2em 3em;
}

.illustrationRegistrar img {
  width: 100%;
}

.Registro .FormLoginContainer .Form h4 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.Registro .FormLoginContainer .Form p {
  font-size: 0.9em;
  margin-bottom: 1em;
}

.Registro .FormLoginContainer .Form label {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em;
  margin-top: 0em;
  margin-bottom: 0em;
  padding: 1em;
  box-sizing: border-box;
}

.gridPlanes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
}

.gridPlanes .plan {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #666;
  border-radius: 1em;
  height: 80px;
}

.Registro .FormLoginContainer .Form label .field {
  width: 100%;
  display: flex;
  align-items: center;
  background: #eee;
  border-radius: 10px;
  height: 45px;
  overflow: hidden;
  margin-top: 0.5em;
}

.Registro .FormLoginContainer .Form label .field input {
  width: 100%;
  background: transparent;
  height: 100%;
  border: none;
  padding: 0em 1em;
  outline: none;
  font-size: 1.1em;
}

.Registro .FormLoginContainer .Form label .field .options {
  height: 100%;
}

.Registro .FormLoginContainer .Form label .field .options button {
  cursor: pointer;
  border: none;
  background: #ddd;
  height: 100%;
  padding: 0.8em;
  box-sizing: border-box;
}

.Registro .FormLoginContainer .Form label .field .options button svg {
  height: 1.5em;
  pointer-events: none;
}

.Registro .FormLoginContainer .Form label .field .options button svg {
  pointer-events: none;
}

.Registro .FormLoginContainer .Form label .field .options button.desactive {
  background: #ddd;
}

.Registro .FormLoginContainer .Form label .field .options button.desactive svg {
  height: 1.8em;
  fill: black;
}

.Registro .FormLoginContainer .Form label .field .options button.active {
  background: black;
}

.Registro .FormLoginContainer .Form label .field .options button.active svg {
  height: 1.8em;
  fill: white;
}

.Registro .FormLoginContainer .Form label .field .options button:hover svg {
  opacity: 1 !important;
}

.optionsSecondaryForm {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8em;
}

.optionsSecondaryForm a {
  font-size: 0.9em;
  width: auto;
  display: inline-block;
  text-align: right;
  color: black;
}

.optionsSecondaryForm label {
  width: 50% !important;
}

.btnRegistro {
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0.5em 2.5em;
  font-family: inherit;
  background: black;
  border: none;
  color: white;
  border-radius: 10px;
  outline: none;
}

.btnRegistro:hover {
  background-color: #111;
}

.btnRegistro:disabled {
  cursor: not-allowed;
  background-color: #d6d6d6;
  color: #b6b6b6;
}

.cardDataInput {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.cardDataInput label {
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}

.cardDataInput label input {
  width: 100%;
  box-sizing: border-box;
}

.tablePlanes {
  background: #f9f9f9;
  border-radius: 0.5em;
  overflow: hidden;
  width: 80% !important;
}

.planesStep {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.infoStep {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}

.infoPayStep {
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  align-content: center !important;
}

.infoStep h4 {
  width: 500px;
  font-size: 1.8em !important;
  margin-bottom: 0em !important;
  text-align: left;
}

.infoStep p {
  width: 500px;
  font-size: 1em !important;
  text-align: left;
}

.actionFixed {
  position: fixed;
  height: 80px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #666;
  background: white;
}

.tables {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 1em;
}

.planesStep h4 {
  width: 500px;
  font-size: 1.8em !important;
  margin-bottom: 0em !important;
}

.infoPayStep p {
  width: 600px;
  font-size: 1em !important;
  align-items: left;
}

.infoPayStep .steps {
  width: 600px;
}

.infoPayStep h4 {
  width: 600px;
  font-size: 1.8em !important;
  margin-bottom: 0em !important;
  align-items: left;
}

.planesStep p {
  width: 500px;
  font-size: 1em !important;
}

.tablePlanes th {
  font-size: 0.8em;
}

.tablePlanes th h4 {
  text-align: center;
  width: 100%;
}

.tablePlanes td {
  font-size: 0.8em;
}

.backButton {
  outline: none;
  background: transparent;
  border: none;
  margin-right: 1em;
  transform: rotate(90deg);
  cursor: pointer;
  padding: 0;
}

.backButton svg {
  width: 1.5em;
  height: 1.5em;
}

.tablePlanes {
  table-layout: fixed;
  width: 48% !important;
  border-collapse: collapse;
  opacity: 0.4;
  cursor: pointer;
}

.tablePlanes:hover {
  opacity: 1;
}

.resumen {
  width: 45%;
  padding: 1.5em;
  box-sizing: border-box;
  background: #eee;
  min-height: 400px;
  margin-left: auto;
  border-radius: 1em;
}

.resumen h4 {
  font-weight: 500 !important;
}

.resumen ul li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.resumen ul li select {
  padding: 0.2em;
  background: white;
}

.tablePlanes.active {
  opacity: 1;
}

.tablePlanes td {
  width: 50%;
  padding: 0.8em;
  font-size: 0.9em;
  margin: 0em 1em;
}

.tablePlanes th {
  width: 50%;
  padding: 0.5em;
  margin: 0em 1em;
}

.tablePlanes tbody tr:nth-child(2n) {
  background: #eee;
}

.tablePlanes th.persona {
  background: rgb(84, 86, 204);
  color: white;
  font-size: 1em;
  width: 50%;
}

.tablePlanes th.asistido {
  background: rgb(246, 126, 56);
  color: white;
  font-size: 1em;
  width: 50%;
}

.tablePlanes th.juridico {
  background: rgb(207, 64, 64);
  color: white;
  font-size: 1em;
  width: 50%;
}

.tablePlanes .priceTable th span {
  text-align: center !important;
  color: white;
  font-size: 0.7em;
  font-weight: normal;
  margin: 0em;
  margin-left: 1em;
}

.priceTable th b {
  font-size: 1.4em;
  margin-bottom: 0em !important;
  font-weight: 500;
}

.priceTable .opacith {
  opacity: 0.8;
}

.btnPlanes {
  margin: auto;
  margin-top: 2em !important;
}

.navRegistro {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 0.5em 1em;
  justify-content: space-between;
  border-bottom: 1px solid black;
  align-items: center;
}

.navRegistro img {
  height: 60px;
}

.navRegistro a {
  text-transform: uppercase;
  color: black;
  text-decoration: none;
}

.FormInfo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.paymentConfirmationModal {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 696969;
}

.paymentConfirmationModal .content h3 {
  margin-bottom: 0;
}

.paymentConfirmationModal .content {
  background-color: white;
  min-width: 30vw;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.paymentConfirmationModal .content .phrase {
  margin-block: 0.25rem;
}

.paymentConfirmationModal .content button {
  margin: 1rem 0;
}
