.MethodsPages {
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-bottom: 2em;
  }
  
  .MethodsPages::-webkit-scrollbar {
    background: transparent;
    width: 0.5em;
  }
  
  .MethodsPages::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 2em;
  }

  .FormMethod .FormContent{
    flex-wrap: wrap;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    align-content: flex-start !important;
  }

  .FormMethod .FormContent .cardContainer{
    width: 100%;
    margin-top: 2em;
    margin-bottom: 0em;
  }

  .FormMethod .FormContent input{
    background: var(--color-primary);
  }

  .FormMethod .FormContent .groupInputs{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
  }

  .FormMethod .Form{
    width: 600px;
  }

  .toggleCustom{
    width: 54px;
    margin-top: auto;
  }
  
  .FormMethod .FormContent .saveForm{
    margin-bottom: auto !important;
    font-size: 1em;
    padding: 0.5em 2em;
    border-radius: 0.5em;
    color: white;
    margin-top: 1em;
    margin-left: 2%;
    font-size: 1.2em;
    background: var(--color-success);
    align-self: center;
  }
  .FormMethod .FormContent .saveForm i{
    margin-left: 0.5em;
    color: white;
  }