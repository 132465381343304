.Migracion{
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Migracion .loadFile{
    background: var(--color-primary);
    width: 90%;
    height: 60vh;
    margin: auto;
    border-radius: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Migracion .loadFile i{
    font-size: 5em;
}

.Migracion .loadFile h3{
    font-size: 2em;
    margin-top: 0em;
    font-weight: 400;
}

.Migracion .loadFile button{
    background: var(--color-text);
    color: var(--color-primary);
    padding: 0.5em 1em;
    border-radius: 2em;
    font-family: inherit;
    font-size: 1.2em;
}