.Productos {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 120px;
}

.Productos::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Productos::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}