.indexConf {
    width: 20%;
    border-left: 1px solid var(--color-border);
    height: 90%;
}
  
.indexConf ul {
    padding-left: 0.8em;
}
  
.indexConf ul li {
    list-style: none;
    margin-bottom: 1em;
}
  
.indexConf ul li a {
    text-decoration: none;
    color: var(--color-text);
    font-size: 0.9em;
}