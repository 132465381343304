.ControllerChartsDashboard{
    width: 100%;
    display: flex;
}

.ControllerChartsDashboard label{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin: 0px 6px;
}

.ControllerChartsDashboard label select{
    font-size: 12px;
    font-family: inherit;
    background: var(--color-primary-variant);
    border: none;
    padding: 0.5em;
    border-radius: 6px;
    margin-top: 4px;
    outline: none;
}

.periodTypeContainer{
    margin-left: auto !important;
}