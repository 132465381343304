.formatsGroup{
    width: 100%;
    padding: 1.5em;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5em;
}

.formatsGroup .formatOption{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 1em 0em 0em 0em;
    border: 2px solid var(--color-text);
    border-radius: 0.5em;
    cursor: pointer;
    position: relative;
    opacity: 0.6;
}

.formatsGroup .formatOption .checkSymbol{
    position: absolute;
    width: 30px;
    height: 30px;
    font-size: 30px;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    top: -15px;
    right: -15px;
    background: var(--color-text);
}

.formatsGroup .formatOption.activeFormat, .formatsGroup .formatOption:hover{
    opacity: 1;
}

.formatsGroup .formatOption.activeFormat .checkSymbol{
    display: flex;
}

.formatsGroup .formatOption .checkSymbol i{
    color: var(--color-primary);
}

.formatsGroup .formatOption .contentMain i{
    font-size: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 14px;
}

.formatsGroup .formatOption .contentMain span{
    font-size: 0.9em;
}

.formatsGroup .formatOption .contentMain{
    padding: 1em 0em;
}

.formatsGroup .formatOption button{
    width: 100%;
    margin-top: 0.5em;
    background:var(--color-text);
    color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0em;
    cursor: pointer;
    border-radius: 0em 0em 0.3em 0.3em;
}

.formatsGroup .formatOption button i{
    margin-left: 0.5em;
    font-size: 1.5em;
}

.formatsGroup .formatOption button i, 
.formatsGroup .formatOption button b{
    color: var(--color-primary);
}

.formatsGroup .formatOption.activeFormat{
    border: 3px solid var(--color-text);
}