.Ingresar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
}

.Ingresar .FormLoginContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em;
  height: 80vh;
  box-sizing: border-box;
  margin: auto;
}

.Ingresar .FormLoginContainer .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Ingresar .FormLoginContainer .logo img {
  width: 400px;
  height: auto;
}

.Ingresar .FormLoginContainer .titleIngresar {
  font-size: 2em;
  margin-bottom: 0em;
}

.Ingresar .FormLoginContainer .Form {
  background: var(--color-primary);
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 2em;
}

.illustrationIngresar {
  width: 45%;
  position: sticky;
  top: 0;
  height: 100vh;
  background: white;
  display: flex;
  align-items: center;
}

.illustrationIngresar img {
  width: 100%;
}

.Ingresar .FormLoginContainer .Form h4 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0;
}

.Ingresar .FormLoginContainer .Form p {
  font-size: 0.9em;
  margin-bottom: 1.5em;
}

.Ingresar .FormLoginContainer .Form label {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em;
  margin-top: 0.8em;
}

.Ingresar .FormLoginContainer .Form label .field {
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--color-primary-variant);
  border-radius: 10px;
  height: 45px;
  overflow: hidden;
  margin-top: 0.5em;
}

.Ingresar .FormLoginContainer .Form label .field input {
  width: 100%;
  background: transparent;
  height: 100%;
  border: none;
  padding: 0em 1em;
  outline: none;
  font-size: 1.1em;
}

.Ingresar .FormLoginContainer .Form label .field .options {
  height: 100%;
}

.Ingresar .FormLoginContainer .Form label .field .options button {
  border: none;
  background: var(--color-text);
  height: 100%;
  cursor: pointer;
  padding: 0.8em;
  box-sizing: border-box;
}

.Ingresar .FormLoginContainer .Form label .field .options button i {
  color: var(--color-primary);
}

.Ingresar .FormLoginContainer .Form label .field .options button i {
  pointer-events: none;
}

.Ingresar .FormLoginContainer .Form label .field .options button.desactive {
  background: var(--color-text);
}

.Ingresar
  .FormLoginContainer
  .Form
  label
  .field
  .options
  button.desactive
  i::before {
  color: var(--color-primary-variant);
  font-size: 1.5em;
}

.Ingresar .FormLoginContainer .Form label .field .options button.active {
  background: var(--color-text);
  opacity: 0.7;
}

.Ingresar .FormLoginContainer .Form label .field .options button.active i {
  color: var(--color-primary);
  font-size: 1.5em;
}

.Ingresar .FormLoginContainer .Form label .field .options button:hover svg {
  opacity: 1 !important;
}

.optionsSecondaryForm {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8em;
}

.optionsSecondaryForm a {
  font-size: 0.9em;
  width: auto;
  display: inline-block;
  text-align: right;
  color: var(--color-text);
}

.optionsSecondaryForm label {
  width: 50% !important;
}

.Ingresar .FormLoginContainer,
.Form .btnIngresar {
  cursor: pointer;
  margin-top: 1em;
  font-size: 1em;
  padding: 0.5em 2.5em;
  font-family: inherit;
  border: none;
  border-radius: 10px;
  outline: none;
}

.Form .btnIngresar {
  color: var(--color-primary);
  background: var(--color-text);
}

.registerLink {
  margin-top: 2em;
}

.registerLink a {
  font-weight: bold;
  color: var(--color-text);
}

.noneMargin {
  margin-top: 0.5em;
  margin-bottom: 0em !important;
}

@media screen and (max-width: 1024px) {
  .Ingresar .FormLoginContainer {
    display: flex;
    height: 100dvh;
    width: 90%;
    margin: 0 auto;
  }
  .illustrationIngresar {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .Ingresar .FormLoginContainer {
    width: 95%;
    padding: 0 10px;
  }
  .Ingresar .FormLoginContainer .logo img {
    width: 100%;
  }
  .Ingresar .FormLoginContainer .Form h4 {
    font-size: 1.2em;
  }
}
