.HistorialPages {
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-bottom: 2em;
  }
  
  .HistorialPages::-webkit-scrollbar {
    background: transparent;
    width: 0.5em;
  }
  
  .HistorialPages::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 2em;
  }

  .pandingAlert{
      width: 95%;
      border-radius: 0.5em;
      box-sizing: border-box;
      padding: 1em 2em;
      margin: 0em auto 2em auto;
      background: var(--color-error);
      color: white;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
  }

  .pandingAlert h3{
      color: white;
      font-size: 1.5em;
      margin: 0em 0em;
      font-weight: bold;
      width: 100%;
  }

  .pandingAlert p{
      color: white;
      font-size: 0.9em;
      width: 70%;
  }

  .pandingAlert a{
      border-radius: 2em;
      color: black;
      background: white;
      padding: 0.5em 2em;
      font-family: inherit;
      font-size: 1em;
  }