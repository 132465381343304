.Configuraciones {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.Configuraciones .mainConf {
  width: 78%;
  height: 100%;
  box-sizing: border-box;
  padding: 0em 0em;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 70px;
}

.Configuraciones .mainConf .sectionMainConf {
  width: 700px;
  margin-bottom: 2em;
}

.Configuraciones .mainConf .sectionMainConf h4 {
  font-weight: 500;
  color: var(--color-text);
}

.Configuraciones .mainConf::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Configuraciones .mainConf::-webkit-scrollbar-thumb {
  background: var(--color-border);
  border-radius: 2em;
}

.Configuraciones .mainConf .sectionMainConf .confSection {
  min-height: 100px;
  overflow: hidden;
  background: var(--color-primary);
  border-radius: 10px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
}

.labelConf {
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--color-border);
  padding: 1em 1em;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
}

.sectionMainConf .confSection .labelConf:last-child {
  border-bottom: none !important;
}

.labelConf a {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.labelConf span {
  font-size: 0.9em;
  color: var(--color-text);
}

.labelConf input {
  border: none;
  border-radius: 5px;
  background: var(--color-primary-variant);
  padding: 0.5em 1em;
  outline: none;
  font-size: 0.9em;
  width: 250px;
  font-family: inherit;
  color: var(--color-text);
}

.labelConf select {
  border: none;
  border-radius: 5px;
  background: var(--color-primary-variant);
  width: 280px;
  padding: 0.5em 1em;
  outline: none;
  font-size: 0.9em;
  font-family: inherit;
  color: var(--color-text);
}

.saveConf,
.deleteAccount {
  margin: 1em;
  background: var(--color-success);
  color: white;
  font-weight: 500;
  font-family: inherit;
  border-radius: 5px;
  border: none;
  padding: 0.5em 1em;
  font-size: 1em;
}

.presentProfile {
  border-bottom: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deleteAccount {
  background: var(--color-error);
  color: white;
}

.present .avatar i {
  font-size: 2em;
  fill: var(--color-text);
}

.present .avatar {
  margin-right: 1em;
}

.present {
  display: flex;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
}

.present .infoUser {
  align-content: center;
}

.present .infoUser h4 {
  font-size: 1em;
  margin-bottom: 0em !important;
  margin-top: 0em !important;
  font-weight: bold !important;
}

.present .infoUser p {
  font-size: 0.8em;
  margin: 0;
  color: var(--color-text);
}

.labelConf .complateLabel {
  width: 100%;

  flex-direction: column;
  display: flex;
}

.labelConf .complateLabel span {
  margin-bottom: 1em;
}

.labelConf .complateLabel input,
.labelConf .complateLabel textarea {
  background: var(--color-primary-variant);
  color: var(--color-text);
  border: none;
  border-radius: 5px;
  width: 100%;
  padding: 0.5em;
  font-size: 0.9em;
  font-family: inherit;
  outline: none;
  box-sizing: border-box;
}

.labelConf .complateLabel input::placeholder,
.labelConf .complateLabel textarea::placeholder {
  color: var(--color-text);
}

.labelConf .complateLabel .gridColors {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelConf .complateLabel .gridColors .colorItem {
  border-radius: 50%;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); */
  border: 2px solid var(--color-text);
  cursor: pointer;
  width: 60px;
  height: 60px;
  /* overflow: hidden; */
  position: relative;
}

.containerPrimary {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}

.labelConf .complateLabel .gridColors .colorItem .primary {
  width: 50%;
  height: 100%;
}

.labelConf .complateLabel .gridColors .colorItem i {
  font-size: 2em;
  color: var(--color-text);
  position: absolute;
  top: -0.6em;
  right: -0.6em;
  display: none;
}

.labelConf .complateLabel .gridColors .colorItem.active {
  border: 3px solid var(--color-text) !important;
}

.labelConf .complateLabel .gridColors .colorItem.active i {
  display: inline-block;
}

.logOutUserBtn {
  background: var(--color-error) !important;
  color: white;
}

@media screen and (max-width: 1024px) {
  .Configuraciones {
    flex-direction: column-reverse;
  }
  .indexConf {
    width: 100%;
    height: max-content;
  }
  .Configuraciones .mainConf {
    width: 100%;
  }
  .Configuraciones .mainConf .sectionMainConf {
    width: 95%;
  }
  .labelConf .complateLabel .gridColors {
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-around;
  }
}
