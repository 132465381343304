.loader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.loader::after {
  --color-three: var(--color-info);

  content: ' ';
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 6px solid var(--color-three);
  border-color: var(--color-three) transparent var(--color-three) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
