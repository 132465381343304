.ShareDocumentoModal.toggled{
    display: none;
}

.ShareDocumentoModal{
    position: fixed;
    display: flex;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: flex-start;
}

.ShareDocumentoModal .cardShare{
    width: 100%;
    max-width: 700px;
    background: var(--color-primary);
    margin-top: 2em;
    border-radius: 1em;
    box-sizing: border-box;
    padding: 1em 2em;
    overflow: hidden;
}

.ShareDocumentoModal .cardShare h3{
    font-weight: 400;
    font-size: 1.4em;
}

.ShareDocumentoModal .cardShare .navCard{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ShareDocumentoModal .cardShare .navCard button{
    background: transparent;
    cursor: pointer;
}


.ShareDocumentoModal .cardShare .optionsShareContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 1em 0em;
    padding-bottom: 2em;
    padding-top: 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
}

/* .ShareDocumentoModal .cardShare .optionsShareContainer button{
    margin: 0em 1.5em 0em 0em;
} */

.copyContainer{
    background: var(--color-primary-variant);
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    padding: 0.5em;
    border-radius: 0.5em;
}

.copyContainer span{
    font-size: 0.85em;
}

.copyContainer{
    margin-bottom: 1em;
}

.copyContainer button{
    cursor: pointer;
}