.DocumentosRecibidos {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 70px;
}

.DocumentosRecibidos::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.DocumentosRecibidos::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.keyBtnTable {
  color: black;
  text-decoration: underline;
  padding: 0.5em 0em !important;
  border-radius: 2em;
  font-family: inherit;
  font-size: 1em;
}

.modal-download {
  width: 500px;
  position: fixed;
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  color: #f7f7f7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.title-download {
  color: #f7f7f7;
}
.buttons-download {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-around;
}

.button-download {
  cursor: pointer;
  color: #f7f7f7;
  width: 150px;
  height: 35px;
  background-color: #4b4b4b;
  display: grid;
  place-items: center;
  font-size: 18px;
  border-radius: 8px;
}