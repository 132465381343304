.ChartsDashboard{
    width: 100%;
    background: var(--color-primary);
    padding: 1em;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    color: #e7e7e7 !important;
    z-index: 10000;
}
